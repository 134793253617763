import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
	/* stepper: {
		padding: theme.spacing(0, 0, 0),
	}, */
	buttons: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: theme.spacing(6),
	},
	button: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	backButtonHidden: {
		visibility: 'hidden',
	},
}));
