import React from "react"
import { Typography } from "@material-ui/core"
import "./checkoutsuccess.css"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"

function CheckoutSuccess() {
  const { aiFormData, activityQuoteResponse, policyStartDate } = useAIForm()
  const { quoteId } = activityQuoteResponse[0]

  const { organisation = {}, policyNumber } = aiFormData
  const { emailAddress = "" } = organisation

  const API_ENDPOINT = process && process.env && process.env.REACT_APP_API_URL

  return (
    <React.Fragment>
      <Typography variant='h5' gutterBottom className='text'>
        Many thanks for your purchase.
      </Typography>
      <Typography variant='subtitle1' gutterBottom className='text'>
        We can confirm your policy is now in force from the date requested{" "}
        <span style={{ fontWeight: "bold" }}>
          {" "}
          {policyNumber[1].substring(0, 10) + " " + policyNumber[1].slice(-4)}
        </span>
      </Typography>
      <Typography variant='subtitle1' gutterBottom className='text'>
        Your policy number is{" "}
        <span style={{ color: "#41BAAE", fontWeight: "bold" }}>
          {policyNumber[0]}
        </span>{" "}
        and we have emailed your policy documents to the designated email
        address provided.
      </Typography>
      <Typography variant='subtitle1' className='text'>
        For additional information, please contact us at 0207 078 7540 or email
        us at info@activitybusinesscover.co.uk
      </Typography>
    </React.Fragment>
  )
}

export default CheckoutSuccess
