import React, { useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import "./addressform.css"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import useStyles from "../../CheckoutPage/styles"
import axios from "axios"
import FormHelperText from "@mui/material/FormHelperText"
import checkUKTelephone from "../../Common/phoneValidation"
import LoadingButton from "@mui/lab/LoadingButton"

const ERRORS = {
  firstName: "",
  lastName: "",
  businessName: "",
  businessType: "",
  email: "",
  phone: "",
  postcode: "",
  flatNo: "",
  houseNumberAndStreet: "",
  address2: "",
  address3: "",
  city: "",
  county: ""
}

const businessTypeData = [
  { id: 1, name: "Individual", value: "INDIVIDUAL" },
  { id: 2, name: "Sole Trader", value: "SOLE_TRADER" },
  { id: 3, name: "Ltd. Co.", value: "LTD_CO" },
  { id: 4, name: "CIC", value: "CIC" },
  { id: 5, name: "Club", value: "Club" },
  { id: 6, name: "Charity", value: "CHARITY" },
  { id: 7, name: "Plc", value: "PLC" },
  { id: 8, name: "Trustee", value: "TRUSTEE" },
  { id: 9, name: "Partnership", value: "PARTNERSHIP" },
  { id: 10, name: "Other", value: "OTHER" }
]

const CHECK_TELEPHONE_UK = "^(0)\\d{9,10}$"

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const ADDRESS_REGEX = "" // removed address validation

export default function() {
  const classes = useStyles()
  const {
    aiFormData,
    updateAIFormFields,
    next,
    activityQuoteResponse,
    back,
    setActivityQuoteResponse
  } = useAIForm()
  const { total } = activityQuoteResponse[0]
  const { organisation = {} } = aiFormData
  const { address = {} } = organisation
  const {
    organisationFirstName = "",
    organisationLastName = "",
    organisationName = "",
    organisationType = "",
    emailAddress = "",
    phone = "",
    salutation = ""
  } = organisation
  const {
    postcode = "",
    flatNo = "",
    address1 = "",
    address2 = "",
    address3 = "",
    address4 = "",
    address5 = ""
  } = address

  const [errors, setErrors] = useState(ERRORS)
  const [loading, setLoading] = useState(false)
  const [validatingPostCode, setValidatingPostCode] = useState(false)
  const [availableAddresses, setAvailableAddresses] = useState([])
  const [postcodeMenu, setPostcodeMenu] = useState(false)

  // handlers
  const handleFirstName = (event) => {
    setErrors((p) => {
      return { ...p, firstName: "" }
    })
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...aiFormData.organisation,
        organisationFirstName: event.target.value
      }
    })
    validateFirstName(event.target.value)
  }

  // handle last name
  const handleLastName = (event) => {
    setErrors((p) => {
      return { ...p, lastName: "" }
    })
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...aiFormData.organisation,
        organisationLastName: event.target.value
      }
    })
    validateLastName(event.target.value)
  }

  // handle business name
  const handleBusinessName = (event) => {
    setErrors((p) => {
      return { ...p, businessName: "" }
    })
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...aiFormData.organisation,
        organisationName: event.target.value
      }
    })
    // validateBusinessName(event.target.value) removed business name validation
  }

  // handle business type
  const handleBusinessType = (event) => {
    setErrors((p) => {
      return { ...p, businessType: "" }
    })
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...aiFormData.organisation,
        organisationType: event.target.value
      }
    })
    validateBusinessType(event.target.value)
  }

  // handle email
  const handleEmail = (event) => {
    setErrors((p) => {
      return { ...p, email: "" }
    })
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...aiFormData.organisation,
        emailAddress: event.target.value
      }
    })
    validateEmail(event.target.value)
  }

  // handle phone
  const handlePhone = (event) => {
    setErrors((p) => {
      return { ...p, phone: "" }
    })
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...aiFormData.organisation,
        phone: event.target.value
      }
    })
    validatePhone(event.target.value)
  }

  // handle postcode
  const handlePostcode = (event) => {
    setErrors((p) => ({ ...p, postcode: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          postcode: event.target.value.toUpperCase()
        }
      }
    })
    validatePostcode(event.target.value)
  }

  // handle flat no
  const handleFlatNo = (event) => {
    setErrors((p) => ({ ...p, flatNo: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          flatNo: event.target.value
        }
      }
    })
    validateFlatNo(event.target.value)
  }

  // handle house number and street
  const handleHouseNumberAndStreet = (val) => {
    setErrors((p) => ({ ...p, houseNumberAndStreet: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          address1: val
        }
      }
    })
    validateHouseNumberAndStreet(val)
  }

  // handle Address_2
  const handleAddress2 = (val) => {
    setErrors((p) => ({ ...p, address2: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          address2: val
        }
      }
    })
  }

  // handle Address_3
  const handleAddress3 = (val) => {
    setErrors((p) => ({ ...p, address3: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          address3: val
        }
      }
    })
  }

  // handle city
  const handleCity = (val) => {
    setErrors((p) => ({ ...p, city: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          address4: val
        }
      }
    })
    //  validateCity(val) removed city validation
  }

  //handle county
  const handleCounty = (val) => {
    setErrors((p) => ({ ...p, county: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          address5: val
        }
      }
    })
    validateCounty(val)
  }

  function populateFieldsByPostCode(param) {
    setErrors((p) => ({ ...p, houseNumberAndStreet: "" }))
    setErrors((p) => ({ ...p, county: "" }))
    setErrors((p) => ({ ...p, county: "" }))
    setErrors((p) => ({ ...p, city: "" }))
    setErrors((p) => ({ ...p, county: "" }))
    updateAIFormFields({
      ...aiFormData,
      organisation: {
        ...organisation,
        address: {
          ...address,
          ...param
        }
      }
    })
    validateHouseNumberAndStreet(param.address1)
    validateCity(param.address4)
    validateCounty(param.address5)
  }

  // validations
  // validate first name
  function validateFirstName(firstName) {
    if (firstName === "") {
      setErrors((p) => ({ ...p, firstName: "Please enter your first name" }))
      return false
    }
    if (!firstName.match("^(?=.{2,40}$)[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$")) {
      setErrors((p) => ({ ...p, firstName: "Please enter valid first name" }))
      return false
    }
    return true
  }

  // validate last name
  function validateLastName(lastName) {
    if (lastName === "") {
      setErrors((p) => ({ ...p, lastName: "Please enter your last name" }))
      return false
    }
    if (!lastName.match("^(?=.{2,40}$)[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$")) {
      setErrors((p) => ({ ...p, lastName: "Please enter valid last name" }))
      return false
    }
    return true
  }

  // validate business name
  function validateBusinessName(businessName) {
    if (businessName === "") {
      setErrors((p) => ({ ...p, businessName: "Please enter business name" }))
      return false
    }
    if (
      !businessName.match(
        "^(?!\\s)(?!.*\\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$"
      )
    ) {
      setErrors((p) => ({
        ...p,
        businessName: "Please enter valid first name"
      }))
      return false
    }
    return true
  }

  // validate business type
  function validateBusinessType(businessType) {
    if (businessType === "") {
      setErrors((p) => ({ ...p, businessType: "Please select business type" }))
      return false
    }
    return true
  }

  // validate email
  function validateEmail(email) {
    if (email === "") {
      setErrors((p) => {
        return { ...p, email: "Please enter your email" }
      })
      return false
    }
    if (!email.match(EMAIL_REGEX)) {
      setErrors((p) => {
        return { ...p, email: "Please enter a valid email" }
      })
      return false
    }
    return true
  }

  // validate phone
  function validatePhone(phone) {
    if (checkUKTelephone(phone)) {
      setErrors((p) => {
        return { ...p, phone: "Please enter a valid phone number" }
      })
      return false
    }

    return true
  }

  // validate postcode
  async function validatePostcode(postcode = "", skip) {
    if (postcode === "" || postcode.length < 5) {
      setErrors((p) => {
        return { ...p, postcode: "Please enter your postcode" }
      })
      return false
    }
    const isValid = await serverPostCodeValidation(postcode, skip)
    return isValid ?? false
  }

  async function serverPostCodeValidation(postcode, skip) {
    try {
      setValidatingPostCode(true)
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.get(
        `${API_ENDPOINT}/api/v1/postcode-lookup/${postcode}?expand=true&fuzzy=true`
      )
      const data = response && response.data && response.data.addresses
      if (data) setAvailableAddresses(data)
      if (!skip) setPostcodeMenu(true)
      return true
    } catch (error) {
      setErrors((p) => {
        return { ...p, postcode: "Please enter valid postcode" }
      })
      return false
    } finally {
      setValidatingPostCode(true)
    }
  }

  // validate validateFlatNo
  function validateFlatNo(no) {
    if (no === "") return true
    if (!no.match("^(\\s*|\\d+)$")) {
      setErrors((p) => {
        return { ...p, flatNo: "Please enter valid flat number" }
      })
      return false
    }
    return true
  }

  // validate house number and street
  function validateHouseNumberAndStreet(houseNumberAndStreet) {
    if (houseNumberAndStreet === "") {
      setErrors((p) => {
        return {
          ...p,
          houseNumberAndStreet: "Please enter your house number and street"
        }
      })
      return false
    }
    if (!houseNumberAndStreet.match(ADDRESS_REGEX)) {
      setErrors((p) => {
        return {
          ...p,
          houseNumberAndStreet: "Please enter valid house number and street"
        }
      })
      return false
    }
    return true
  }

  // validate city
  function validateCity(city) {
    if (city === "") {
      setErrors((p) => {
        return { ...p, city: "Please enter your city" }
      })
      return false
    }
    /*  if (!city.match("^[a-zA-Z]+(?:[\\s-][a-zA-Z]+)*$")) {
      setErrors((p) => {
        return { ...p, city: "Please enter valid city" }
      })
      return false 
    }*/
    return true
  }

  // validate city
  function validateCounty(county = "") {
    if (county === "") return true
    if (!county.match("^[a-zA-Z]+(?:[\\s-][a-zA-Z]+)*$")) {
      setErrors((p) => {
        return { ...p, county: "Please enter a valid county." }
      })
      return false
    }
    return true
  }

  function validateAll() {
    let isPostCodeValid = (function() {
      if (postcode === "") {
        setErrors((p) => {
          return { ...p, postcode: "Please enter your postcode" }
        })
        return false
      } else if (postcode.length < 5 || postcode.length > 8) {
        setErrors((p) => {
          return { ...p, postcode: "Please enter valid postcode" }
        })
        return false
      }
      return true
    })()
    /* await isPostCodeValid */
    let isFirstNameValid = validateFirstName(organisationFirstName)
    let isLastNameValid = validateLastName(organisationLastName)
    /* let isBusinessNameValid = validateBusinessName(organisationName) */
    let isBusinessTypeValid = validateBusinessType(organisationType)
    let isEmailValid = validateEmail(emailAddress)
    let isPhoneValid = validatePhone(phone)
    let isFlatNoValid = validateFlatNo(flatNo)
    let isHAndSValid = validateHouseNumberAndStreet(address1)
    let isCityValid = validateCity(address4)
    let isCountyValid = validateCounty(address5)

    return (
      isFirstNameValid &&
      isLastNameValid &&
      /*  isBusinessNameValid && */
      isBusinessTypeValid &&
      isEmailValid &&
      isPhoneValid &&
      isPostCodeValid &&
      isFlatNoValid &&
      isHAndSValid &&
      isCityValid &&
      isCountyValid
    )
  }

  async function fetchQuoteFromAPI() {
    try {
      setLoading(true)
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.post(`${API_ENDPOINT}/api/v1/lansdown`, {
        ...aiFormData,
        organisation: {
          ...aiFormData.organisation,
          salutation: aiFormData.organisation || ""
        },
        organisation: {
          ...aiFormData.organisation,
          address: {
            ...aiFormData.organisation.address,
            address2: aiFormData.organisation.address.address2 || "",
            address3: aiFormData.organisation.address.address3 || ""
          }
        },
        quoteId: +String(Date.now() + Math.random()).replace(".", ""),
        uniquePolicyNumber: String(Date.now() + Math.random()).replace(".", ""),
        pl_cover_amt: +aiFormData.pl_cover_amt,
        pl_turnover: +aiFormData.pl_turnover,
        el_wageroll: +aiFormData.el_wageroll,
        equipment_cover_amt: +aiFormData.equipment_cover_amt,
        uk_only: true,
        ngb_qualified: true,
        hazardous_materials: true,
        appropriate_training: true,
        risk_assessment: true,
        participation_form: true,
        safety_briefing: true,
        correct_operation: true,
        previous_claims: true,
        insurance_declaration: true
      })
      const data = response && response.data
      setActivityQuoteResponse(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      throw error
    }
  }

  function handleSelectedAddress({ formatted_address }) {
    setPostcodeMenu(false)
    let updateObj = {}
    if (formatted_address[0])
      updateObj = { ...updateObj, address1: formatted_address[0] }
    if (formatted_address[1])
      updateObj = { ...updateObj, address2: formatted_address[1] }
    if (formatted_address[2])
      updateObj = { ...updateObj, address3: formatted_address[2] }
    if (formatted_address[3])
      updateObj = { ...updateObj, address4: formatted_address[3] }
    if (formatted_address[4])
      updateObj = { ...updateObj, address5: formatted_address[4] }
    populateFieldsByPostCode(updateObj)
  }

  async function handleNext() {
    const isValid = validateAll()

    if (isValid) {
      console.log("valid", validateAll())
      try {
        setLoading(true)
        await fetchQuoteFromAPI()
        setLoading(false)
        next()
      } catch {}
    }
  }

  //capitalise first letter of each word
  function capitaliseFirstLetter(string) {
    if (!string) return ""
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  }

  return (
    <div className='address-form-page'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='quote-container'>
            <div className='cost-container'>
              <Typography variant='h5' className='text'>
                Your Quote
              </Typography>
              <Typography variant='h5' className='text'>
                {total && `£${total.toFixed(2)}`}
              </Typography>
            </div>
            <Typography variant='body1' className='text tax'>
              Including Insurance Premium Tax
            </Typography>
          </div>
        </Grid>
        <Typography
          variant='h5'
          gutterBottom
          style={{ paddingLeft: "1rem", paddingTop: "1rem" }}
        >
          Your Details
        </Typography>
        <Grid
          container
          spacing={6}
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          {/* Start of first container for input fields */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12}>
              <TextField
                error={!!errors.firstName}
                required
                helperText={errors.firstName}
                value={capitaliseFirstLetter(organisationFirstName)}
                onChange={handleFirstName}
                id='firstName'
                label='First Name'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!!errors.lastName}
                required
                helperText={errors.lastName}
                value={capitaliseFirstLetter(organisationLastName)}
                onChange={handleLastName}
                id='lastName'
                label='Last Name'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!!errors.businessName}
                helperText={errors.businessName}
                value={capitaliseFirstLetter(organisationName)}
                onChange={handleBusinessName}
                id='businessName'
                label='Business Name'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='standard' fullWidth>
                <InputLabel
                  required
                  error={!!errors.businessType}
                  id='businessType'
                >
                  Business Type
                </InputLabel>
                <Select
                  error={!!errors.businessType}
                  labelId='demo-simple-select-standard-label'
                  id='businessType'
                  onChange={handleBusinessType}
                  value={organisationType}
                  label='Business Type'
                >
                  {businessTypeData.map((x) => (
                    <MenuItem key={x.id} value={x.name}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!!errors.businessType && (
                <FormHelperText error>{errors.businessType}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!!errors.email}
                helperText={errors.email}
                value={emailAddress}
                onChange={handleEmail}
                required
                id='email'
                label='Email'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.phone}
                helperText={errors.phone}
                value={phone}
                onChange={handlePhone}
                id='phone'
                label='Phone Number'
                variant='standard'
                fullWidth
              />
            </Grid>
          </Grid>
          {/* End of first container for input fields */}

          {/* Start of second container for input fields */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.postcode}
                helperText={errors.postcode}
                value={postcode.toUpperCase()}
                onChange={handlePostcode}
                id='postcode'
                label='Postcode'
                variant='standard'
                fullWidth
              />
            </Grid>

            {postcodeMenu &&
              (availableAddresses && availableAddresses.length) > 0 && (
                <ul className='postcode-list'>
                  <div
                    style={{
                      color: "#41baae",
                      paddingBottom: "1rem",
                      fontWeight: "bold"
                    }}
                  >
                    Choose your address:
                  </div>
                  {availableAddresses.map((address, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleSelectedAddress(address)}
                      >
                        {/* {address.formattedAddress && <div>{address.formattedAddress.map(e => !!e).join(', ')}</div>} */}
                        <span>{address.formatted_address}</span>
                      </li>
                    )
                  })}
                </ul>
              )}
            <Grid item xs={12}>
              <TextField
                error={!!errors.flatNo}
                helperText={errors.flatNo}
                value={flatNo}
                onChange={handleFlatNo}
                id='flatNo'
                label='Flat No.'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.houseNumberAndStreet}
                helperText={errors.houseNumberAndStreet}
                value={address1}
                onChange={(e) => handleHouseNumberAndStreet(e.target.value)}
                id='houseNumberAndStreet'
                label='House Number and Street'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={address2}
                error={!!errors.address2}
                helperText={errors.address2}
                onChange={(e) => handleAddress2(e.target.value)}
                id='address2'
                label='Address 2'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={address3}
                error={!!errors.address3}
                helperText={errors.address3}
                onChange={(e) => handleAddress3(e.target.value)}
                id='address-3'
                label='Address 3'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.city}
                helperText={errors.city}
                value={address4}
                onChange={(e) => handleCity(e.target.value)}
                id='city'
                label='City'
                variant='standard'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!!errors.county}
                helperText={errors.county}
                value={address5}
                onChange={(e) => handleCounty(e.target.value)}
                id='county'
                label='County'
                variant='standard'
                fullWidth
              />
            </Grid>
          </Grid>
          {/* End of second container for input fields */}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.buttons}>
            <Button
              style={{ marginLeft: "8px" }}
              type='submit'
              variant='contained'
              color='primary'
              size='medium'
              className={classes.button}
              onClick={back}
            >
              Back
            </Button>
            <LoadingButton
              loading={loading}
              type='submit'
              variant='contained'
              color='primary'
              size='medium'
              className={classes.button}
              onClick={handleNext}
            >
              Next
            </LoadingButton>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
