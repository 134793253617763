import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import Typography from "@mui/material/Typography"
import "./statements.css"
import Divider from "@mui/material/Divider"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import Button from "@mui/material/Button"
import useStyles from "../styles"
import FormHelperText from "@mui/material/FormHelperText"
import CheckIcon from "@mui/icons-material/Check"

const ERRORS = {
  confirmed: ""
}

const CONFIRMATION_DTO = {
  uk_only: true,
  ngb_qualified: true,
  risk_assessment: true,
  participation_form: true,
  safety_briefing: true,
  correct_operation: true,
  hazardous_materials: false,
  previous_claims: true,
  insurance_declaration: true,
  trade: "string"
}

export default function Equipment(props) {
  const classes = useStyles()
  const [confirm, setConfirm] = useState(false)
  const [errors, setErrors] = useState(ERRORS)
  const {
    aiFormData,
    updateAIFormFields,
    next,
    activityQuoteResponse,
    setActivityQuoteResponse,
    back
  } = useAIForm()
  const { total } = activityQuoteResponse[0]

  // handlers
  const handleChange = (event) => {
    setErrors((p) => {
      return { ...p, confirmed: "" }
    })
    setConfirm(event.target.checked)
    updateAIFormFields(CONFIRMATION_DTO)
    // no validation call since it checkbox (checked / not checked)
  }

  // validations
  function validateChange(confirm) {
    if (!confirm) {
      setErrors((p) => {
        return { ...p, confirmed: "Please confirm to continue" }
      })
      return false
    }
    return true
  }

  function handleNext() {
    const isValid = validateChange(confirm)
    if (isValid) next()
  }

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [])

  return (
    <div className='public-liabliliy-container'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='quote-container'>
            <div className='cost-container'>
              <Typography variant='h5' className='text'>
                Your Quote
              </Typography>
              <Typography variant='h5' className='text'>
                {total && `£${total.toFixed(2)}`}
              </Typography>
            </div>
            <Typography variant='body1' className='text tax'>
              Including Insurance Premium Tax
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant='h5'
            className='text'
            style={{ paddingBottom: "2rem" }}
          >
            Please read the below statements and confirm they are correct
          </Typography>
          <Typography variant='body1' className='text'>
            Our activities are solely conducted in the United Kingdom
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            All instructors and leaders hold the correct National Governing
            Bodies (NGB) qualification or have had an appropriately
            NGB-qualified technical advisor assess them. Where there is no
            relevant NGB qualification, all instructors and leaders have
            received appropriate training and assessment for, or are
            appropriately experienced to undertake instruction in the activities
            concerned
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            The activities are risk assessed and these are documented
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            A Suitability to Participate form from all participants declaring
            their fitness, existing injuries and/or any medical conditions is
            collected. A copy of said declaration must be retained by the
            Insured and made available for Insurers upon request. In respect of
            persons under 16 years, a counter signature by the parent or
            guardian is mandatory. A copy of said declaration must be retained
            by you and made available for Insurers upon request
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            All participants complete and sign a declaration to confirm that: a)
            they have been given a safety briefing and briefed on the risks
            involved b) that they accept that these activities are dangerous and
            can result in death and/or injury and c) that they accept the risks
            involved and are responsible for their own actions and/or
            involvement. In respect of persons under 16 years, a counter
            signature by the parent or guardian is mandatory. A copy of said
            declaration must be retained by you and made available for Insurers
            upon request
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            All equipment is operated in accordance with manufacturers’
            recommendations
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            No hazardous materials are stored
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            I/we have had no previous insurance claims within the last 5 years
          </Typography>
          <Divider style={{ background: "#41BAAE" }} />
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          >
            The proposer(s), or any partner or director has never:
          </Typography>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0", display: "flex" }}
          >
            <CheckIcon
              fontSize='small'
              color='primary'
              style={{ marginTop: "2px" }}
            />
            <span style={{ paddingBottom: "0", marginLeft: "2rem" }}>
              Had any proposal for insurance cancelled or declined for
            </span>
          </Typography>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0", display: "flex" }}
          >
            <CheckIcon
              fontSize='small'
              color='primary'
              style={{ marginTop: "2px" }}
            />
            <span style={{ paddingBottom: "0", marginLeft: "2rem" }}>
              Been the subject of any form of bankruptcy, insolvency or winding
              up procedure either as private individuals or in connection with
              any business in the last 5 years
            </span>
          </Typography>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0", display: "flex" }}
          >
            <CheckIcon
              fontSize='small'
              color='primary'
              style={{ marginTop: "2px" }}
            />
            <span style={{ paddingBottom: "0", marginLeft: "2rem" }}>
              Been prosecuted and there are no prosecutions pending under the
              Health and Safety at Work Act or any other statute or regulation
            </span>
          </Typography>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0", display: "flex" }}
          >
            <CheckIcon
              fontSize='small'
              color='primary'
              style={{ marginTop: "2px" }}
            />
            <span style={{ paddingBottom: "0", marginLeft: "2rem" }}>
              Been convicted of, prosecuted for and there are no prosecutions
              pending for any criminal offence (other than motoring convictions)
            </span>
          </Typography>
          <Divider style={{ background: "#41BAAE", marginTop: "2rem" }} />
          <FormControlLabel
            style={{
              paddingTop: "2rem",
              marginLeft: "0",
              marginRight: "1rem"
            }}
            value='start'
            control={
              <Checkbox
                checked={confirm}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label='I confirm I have read the above and confirm the statements are true'
            labelPlacement='start'
          />
          {errors.confirmed && (
            <FormHelperText error>{errors.confirmed}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='medium'
          className={classes.button}
          onClick={back}
        >
          Back
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='medium'
          className={classes.button}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
