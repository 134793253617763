import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import Typography from "@mui/material/Typography"
import "./publicLiability.css"
import TextField from "@mui/material/TextField"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import useStyles from "../styles"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { isValid } from "date-fns"
import { NumericFormat } from "react-number-format"
import OutlinedInput from "@mui/material/OutlinedInput"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Tooltip from "@mui/material/Tooltip"
import { display } from "@mui/system"

// modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "10px",
  bgcolor: "background.paper",
  border: "2px solid #41BAAE",
  boxShadow: 24,
  p: 4
}

// tootilp text
const tooltipTitle = () => {
  return (
    <>
      <Typography variant='body2'>
        {`Public Liability covers you should you be sued by a third party (e.g. a
        customer) for injury to them or damage to their property, up to the limit
        you have selected.`}
      </Typography>
      <br />
      <Typography variant='body2'>
        {`In the event of your business being blamed for an
        incident outlined above, your Public Liability will provide cover for the
        legal costs associated with defending you in court plus any damages that are
        deemed payable.`}
      </Typography>
      <br />
      <Typography variant='body2'>
        {`Our Public Liability Policy includes cover for negligent
        instruction and advice you/your instructors may provide to your clients,
        which may result in a bodily injury.`}
      </Typography>
    </>
  )
}

const ERRORS = {
  turnover: "",
  cover: ""
}
const NUMERIC_STRING_REGEX = `[0-9]+(,[0-9]+)*`
let debounceTimeoutId

export default function PublicLiability() {
  const classes = useStyles()
  const {
    aiFormData,
    updateAIFormFields,
    next,
    activityQuoteResponse,
    back,
    fetchQuickQuoteFromAPI
  } = useAIForm()
  const { total, refer, refer_reason } = activityQuoteResponse[0]
  let { pl_turnover = "", pl_cover_amt = "" } = aiFormData
  const [errors, setErrors] = useState(ERRORS)
  const [loading, setLoading] = useState(false)
  const [isQuickQuoteApiCalled, setIsQuickQuoteApiCalled] = useState(false)
  const [turnoverFEdisplay, setTurnoverFEdisplay] = useState("") // for FE display only (with commas)

  //modal
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)

  const handleClose = (event, reason) => {
    // prevent closing on backdrop click
    if (reason !== "backdropClick") {
      updateAIFormFields({ pl_cover_amt: "", pl_turnover: "" })
      setTurnoverFEdisplay("")
      setOpen(false)
    }
  }

  function ModalComponent() {
    return (
      <Modal
        disableEscapeKeyDown={true}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h5'
            component='h2'
            sx={{ textAlign: "center", color: "#41BAAE" }}
          >
            Activity Business Cover
          </Typography>
          <Typography
            id='modal-modal-description'
            variant='body1'
            sx={{ mt: 4 }}
          >
            Your business is outside of our on-line limits because of the{" "}
            <strong>{refer_reason}</strong>.
          </Typography>

          <Typography
            id='modal-modal-description'
            variant='body1'
            sx={{ mt: 4 }}
          >
            Please call us on 0207 078 7540 to speak with an advisor.
          </Typography>
          <div className='center'>
            <Button variant='contained' sx={{ mt: 4 }} onClick={handleClose}>
              CONFIRM
            </Button>
          </div>
        </Box>
      </Modal>
    )
  }
  // end modal

  // handle turnover input change (with commas)
  const handleChangeturnoverFEdisplay = (event) => {
    setTurnoverFEdisplay(event.target.value)
  }

  // handlers
  const handleTurnoverChange = (event) => {
    setErrors((p) => {
      return { ...p, turnover: "" }
    })
    handleChangeturnoverFEdisplay(event)
    updateAIFormFields({
      pl_turnover: event.target.value.replaceAll(",", "")
    })
    validateTurnover(event.target.value)
  }

  const handleCoverChange = (event) => {
    setErrors((p) => {
      return { ...p, cover: "" }
    })
    updateAIFormFields({ pl_cover_amt: event.target.value })
  }

  // validations
  function validateTurnover(turnover) {
    if (turnover === "" || turnover === "0") {
      setErrors((p) => ({
        ...p,
        turnover: "Please enter your estimated annual turnover"
      }))
      return false
    }

    if (!turnover.match(NUMERIC_STRING_REGEX)) {
      setErrors((p) => {
        return { ...p, turnover: "Must be a number" }
      })
      return false
    }
    return true
  }

  function validateCover(cover) {
    if (cover === "") {
      setErrors((p) => {
        return { ...p, cover: "Please select your cover" }
      })
      return false
    }
    return true
  }

  function validateAll() {
    let turnoverisValid = validateTurnover(pl_turnover)
    let coverIsValid = validateCover(pl_cover_amt)
    return turnoverisValid && coverIsValid
  }

  // checks if the the refer is true and if so opens the modal
  const handleReferals = () => {
    if (refer && refer_reason) {
      handleOpen()
    }
  }

  async function checkQuote() {
    const isValid = validateAll()
    if (!isValid) return
    try {
      setIsQuickQuoteApiCalled(false)
      setLoading(true)
      await fetchQuickQuoteFromAPI()
      setIsQuickQuoteApiCalled(true)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  function handleNext() {
    const isValid = validateAll()
    if (isValid) next()
  }

  // if aiFormData object changes
  useEffect(() => {
    // and if pl_turnover & pl_cover_amt properties exists
    if (pl_turnover && pl_cover_amt) {
      // call the API if elapsed time from the last input is greater then 500 millis
      clearTimeout(debounceTimeoutId)
      debounceTimeoutId = setTimeout(() => {
        checkQuote()
      }, 500)
    }
  }, [pl_turnover, pl_cover_amt])

  useEffect(() => {
    if (refer && isQuickQuoteApiCalled) {
      handleReferals()
    }
    if (turnoverFEdisplay == "") {
      setTurnoverFEdisplay(pl_turnover) // when returning from previous page to set value
    }
  }, [refer, isQuickQuoteApiCalled])

  const handleTotal = (total) => {
    switch (total) {
      case 0:
        return "£0.00"
      case null:
        return "£0.00"
      case undefined:
        return "£0.00"
      case NaN:
        return "£0.00"
      default:
        if (refer) {
          return "£0.00"
        }
        return `£${Number(total).toFixed(2)}`
    }
  }

  return (
    <div className='public-liabliliy-container'>
      <>
        <Grid container spacing={3}>
          <ModalComponent />
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className='quote-container'>
              <div className='cost-container'>
                <Typography variant='h5' className='text'>
                  Your Quote
                </Typography>
                <Typography variant='h5' className='text'>
                  {handleTotal(total)}
                </Typography>
              </div>
              <Typography variant='body1' className='text tax'>
                Including Insurance Premium Tax
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className='tooltip'>
              <Typography variant='h5' className='text'>
                Choose your Cover
              </Typography>

              <Tooltip
                title={tooltipTitle()}
                placement='left-start'
                enterTouchDelay={0}
              >
                <InfoOutlinedIcon
                  color='primary'
                  fontSize='large'
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            </div>

            <Typography variant='h6' className='public-liability text'>
              Public Liability
            </Typography>

            <Grid item xs={12} sm={6}>
              <Typography variant='body1' className='text'>
                Please enter your estimated annual turnover
              </Typography>

              <NumericFormat
                customInput={Input}
                startAdornment={
                  <InputAdornment position='start'>£</InputAdornment>
                }
                error={!!errors.turnover}
                value={turnoverFEdisplay}
                onChange={handleTurnoverChange}
                id='standard-basic'
                label='Turnover'
                variant='standard'
                fullWidth
                thousandsGroupStyle='thousand'
                thousandSeparator=','
              />
              {errors.turnover && (
                <FormHelperText error>{errors.turnover}</FormHelperText>
              )}
            </Grid>

            <Typography
              variant='body1'
              gutterBottom
              className='text'
              style={{ paddingTop: "3rem" }}
            >
              How much cover do you require?
            </Typography>
            <FormControl error={!!errors.cover}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={pl_cover_amt}
                onChange={handleCoverChange}
              >
                <FormControlLabel value='2' control={<Radio />} label='£2m' />
                <FormControlLabel
                  className='five-m'
                  value='5'
                  control={<Radio />}
                  label='£5m'
                />
                {/*  <FormControlLabel value='10' control={<Radio />} label='£10m' /> */}
              </RadioGroup>
            </FormControl>
            {errors.cover && (
              <FormHelperText error>{errors.cover}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='medium'
            className={classes.button}
            onClick={back}
          >
            Back
          </Button>

          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='medium'
            className={classes.button}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </>
    </div>
  )
}
