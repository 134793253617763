import React, { useState } from "react"
import "./stripePayment.css"
import Button from "@mui/material/Button"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from "axios"
import { useEffect } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import InstantMessageSuccess from "../Alerts/InstantMessageSuccess"
import { useNavigate } from 'react-router'
import { useLocation } from "react-router-dom";

// Stripe styling
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#41BAAE",
      color: "#36454F",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#36454F" },
      "::placeholder": { color: "#41BAAE" }
    },
    invalid: {
      color: "#ff3333",
      iconColor: "#ff3333"
    }
  }
}

const API_ENDPOINT = process && process.env && process.env.REACT_APP_API_URL

export default function ({ policyStartDate }) {
  const location = useLocation();
  const isRenewal = location.pathname.includes("/renew-policy/");

  const API_ACTION_PATH = isRenewal ? "renewals" : "mta";

  // stripe states
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [quoteByID, setQuoteByID] = useState(null)
  const [secret, setSecret] = useState("")
  const {
    aiFormData,
    setActivityQuoteResponse,
    activityQuoteResponse
  } = useAIForm()
  const { customerPortalData = {} } = aiFormData // new object in context
  const { userInfo } = customerPortalData
  const { token, policyNumber } = userInfo || {}

  const navigate = useNavigate()

  const {
    amendedQuoteData = {},
    totalDueRefund,
    organisation = {},
    premiumAdjustment,
    admin_fee,
    agent_commission,
    agent_commission_rate,
    gross_premium,
    net_premium
  } = activityQuoteResponse

  const {
    quoteId,
    total,
    ipt_amt,
    pl_cover_amt,
    pl_turnover,
    el_wageroll,
    equipment_cover_amt
  } = amendedQuoteData

  const {
    organisationFirstName = "",
    organisationLastName = "",
    organisationName = "",
    emailAddress = "",
    phone = "",
    address = {}
  } = organisation
  const { postcode = "", address1 = "", address4 = "" } = address

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    await confirmStripeCardPayment()
  }


  const [showSuccessMessage, setShowSuccessMessage] = useState(false) // new state variable

  const changeStateOfShowSuccessMessage = () => {
    setShowSuccessMessage(!showSuccessMessage)
  }

  const renderSuccessMessage = () => {
    if (showSuccessMessage) {
      return (
        <InstantMessageSuccess
          severity={"success"}
          title='Success'
          message=
          "Your payment has been processed successfully. You will receive an email shortly."
        />
      )
    }
  }


  async function createPaymentIntent() {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/payment-intent`,
        {
          amount: Math.abs(Math.round((total + Number.EPSILON) * 100)),
          quoteId: quoteId, // new quote id from calculate Amended quote data
          currency: "gbp",
          edited: false,
          isMtaPolicy: true,
          stripePaymentPurpose: "QUOTE",
          admin_fee,
          agent_commission,
          agent_commission_rate,
          gross_premium,
          net_premium,
          ipt_amt,
          total,
          totalDueRefund
        }
      )
      let secret = response && response.data && response.data.clientSecret
      if (response.status === 200) {
        setSecret(secret)
      }
    } catch (error) {
      alert("Payment failed please try again")

      console.log(
        `POST /api/v1/payment-intent | ERROR: ${error && error.message}`
      )
    }
  }

  async function confirmStripeCardPayment() {
    try {
      const payload = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      if (!payload.error) {
        await completePayment(payload)
      } else console.log(payload.error.message)
      setLoading(false)
    } catch (error) {
      console.log(
        `stripe.confirmStripeCardPayment | ERROR: ${error && error.message}`
      )
    }
  }

  async function completePayment(payment) {
    try {
      await axios.post(`${API_ENDPOINT}/api/v1/payment-intent/status`, payment)
      await confirmQuote()
    } catch (error) {
      console.log(
        `POST /api/v1/payment-intent/status | ERROR: ${error && error.message}`
      )
    }
  }

  async function confirmQuote() {
    try {
      setLoading(true)
      const API_ENDPOINT = process.env.REACT_APP_API_URL
      const customerPortalDataWithQuoteId = {
        ...customerPortalData,
        quoteId: quoteId // new quote id from calculate Amended quote data
      }
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/lansdown/${API_ACTION_PATH}/confirm`,
        customerPortalDataWithQuoteId
      )
      const data = response && response.data
      setLoading(false)
      if (response.status === 200 || response.status.text === "OK") {

        changeStateOfShowSuccessMessage()




        finalize()
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      alert("Something went wrong. Please try again later.", error)
      throw error
    }
  }

  function finalize() {
    setSuccess(true)
    setLoading(false)
    setTimeout(() => {
      if (isRenewal) {
        navigate(`/customer-login`)
      } else {
        navigate(`/portal/${token}`)
      }
    }, 3000)

  }

  async function initialize() {
    // await getQuoteByID()
    await createPaymentIntent()
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <form onSubmit={handleSubmit} className='stripe-payment-form'>
      {renderSuccessMessage()}
      <CardElement options={CARD_OPTIONS} className='card' />
      <LoadingButton
        /* onClick={setLoading(true)} */
        loading={loading}
        variant='contained'
        type='submit'
        sx={{ width: "100%" }}
      >
        PAY NOW
      </LoadingButton>
    </form>
  )
}
