import React, { useState } from "react"
import "./stripePayment.css"
import Button from "@mui/material/Button"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from "axios"
import { useEffect } from "react"
import LoadingButton from "@mui/lab/LoadingButton"

// Stripe styling
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#41BAAE",
      color: "#36454F",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#36454F" },
      "::placeholder": { color: "#41BAAE" }
    },
    invalid: {
      color: "#ff3333",
      iconColor: "#ff3333"
    }
  }
}

const API_ENDPOINT = process && process.env && process.env.REACT_APP_API_URL

export default function ({ policyStartDate }) {
  // stripe states
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [quoteByID, setQuoteByID] = useState(null)
  const [secret, setSecret] = useState("")
  const {
    aiFormData,
    next,
    goToDesiredStep,
    activityQuoteResponse,
    updateAIFormFields
  } = useAIForm()



  const { total, quoteId } = activityQuoteResponse[0]

  const { organisation = {} } = aiFormData
  const {
    organisationFirstName = "",
    organisationLastName = "",
    organisationName = "",
    organisationType = "",
    emailAddress = "",
    phone = "",
    salutation = "",
    address = {}
  } = organisation
  const {
    postcode = "",
    flatNo = "",
    address1 = "",
    address2 = "",
    address3 = "",
    address4 = "",
    address5 = ""
  } = address

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    await confirmStripeCardPayment()
  }

  async function getQuoteByID() {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/api/v1/lansdown/get/${quoteId}`
      )
      setQuoteByID(
        response && response.data && response.data.organisationDtoResponse
      )
    } catch (error) {
      console.log(
        error && error.message
          ? `POST /api/v1/lansdown/get/${quoteId} | ERROR: ${error &&
          error.message}`
          : error
      )
    }
  }

  async function createPaymentIntent() {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/payment-intent`,
        {
          amount: Math.round((total + Number.EPSILON) * 100),
          currency: "GBP",
          quoteId: quoteId,
          stripePaymentPurpose: "QUOTE",
          hashedTotalDueRefund: "no"
        }
      )
      let secret = response && response.data && response.data.clientSecret
      if (response.status === 200) {
        setSecret(secret)
      }
    } catch (error) {
      alert("Payment failed please try again")
      goToDesiredStep(0)
      console.log(
        `POST /api/v1/payment-intent | ERROR: ${error && error.message}`
      )
    }
  }

  async function confirmStripeCardPayment() {
    try {
      const payload = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      if (!payload.error) {
        await completePayment(payload)
      } else console.log(payload.error.message)
      setLoading(false)
    } catch (error) {
      console.log(
        `stripe.confirmStripeCardPayment | ERROR: ${error && error.message}`
      )
    }
  }

  async function completePayment(payment) {
    try {
      await axios.post(`${API_ENDPOINT}/api/v1/payment-intent/status`, payment)
      await confirmQuote()
    } catch (error) {
      console.log(
        `POST /api/v1/payment-intent/status | ERROR: ${error && error.message}`
      )
    }
  }

  async function confirmQuote() {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/lansdown/confirm`,
        {
          quoteId,
          organisationFirstName,
          organisationLastName,
          organisation_name: organisationName,
          organisation_type: organisationType,
          email: emailAddress,
          phone,
          flatNo,
          address1,
          address2,
          address3,
          address4,
          address5,
          postcode,
          policyStartDate
        }
      )
      const policyNumber = response && response.data
      updateAIFormFields({ policyNumber })
      finalize()
    } catch (error) {
      console.log(
        `PUT /api/v1/lansdown/confirm | ERROR: ${error && error.message}`
      )
    }
  }

  function finalize() {
    setSuccess(true)
    setLoading(false)
    next()
  }

  async function initialize() {
    // await getQuoteByID()
    await createPaymentIntent()
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <form onSubmit={handleSubmit} className='stripe-payment-form'>
      <CardElement options={CARD_OPTIONS} className='card' />
      <LoadingButton
        /* onClick={setLoading(true)} */
        loading={loading}
        variant='contained'
        type='submit'
        sx={{ width: "100%" }}
      >
        PAY NOW
      </LoadingButton>
    </form>
  )
}
