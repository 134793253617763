import React, { useState, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import axios from "axios"
import { useNavigate, useLocation } from "react-router-dom"

import { createTheme, ThemeProvider } from "@mui/material/styles"
import "./paymentform.css"
import Divider from "@mui/material/Divider"
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { useDevice } from "../../../contexts/DeviceContext"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import StripePaymentPortal from "./StripePaymentPortal"
import LoadingButton from "@mui/lab/LoadingButton"
import InstantMessageSuccess from "../Alerts/InstantMessageSuccess"
import { el } from "date-fns/locale"

const theme = createTheme({
  palette: {
    primary: {
      main: "#41BAAE",
      contrastText: "#fff"
    }
  }
})

const STRIPE_CONNECTED_ACCOUNT_KEY = `${process.env.REACT_APP_STRIPE_CONNECTED_ACCOUNT_ID}`
const STRIPE_KEY = `${process.env.REACT_APP_STRIPE_KEY}`
const stripePromise = loadStripe(STRIPE_KEY, {
  stripeAccount: STRIPE_CONNECTED_ACCOUNT_KEY
})

export default function PaymentForm() {
  const location = useLocation() // Add this to your imports from react-router-dom

  const isRenewal = location.pathname.includes("/renew-policy/")
  const isUpdate = location.pathname.includes("/update-policy/")

  const [showStripe, setShowStripe] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)

  const navigate = useNavigate()

  const {
    aiFormData,
    setActivityQuoteResponse,
    activityQuoteResponse
  } = useAIForm()
  const { customerPortalData = {} } = aiFormData // new object in context
  const { userInfo } = customerPortalData
  const { token, policyNumber } = userInfo || {}

  const {
    amendedQuoteData = {},
    totalDueRefund,
    organisation = {},
    premiumAdjustment,
    admin_fee,
    agent_commission,
    agent_commission_rate,
    gross_premium,
    net_premium
  } = activityQuoteResponse

  const {
    quoteId,
    total,
    ipt_amt,
    pl_cover_amt,
    pl_turnover,
    el_wageroll,
    equipment_cover_amt
  } = amendedQuoteData

  const {
    organisationFirstName = "",
    organisationLastName = "",
    organisationName = "",
    emailAddress = "",
    phone = "",
    address = {}
  } = organisation
  const { postcode = "", address1 = "", address4 = "" } = address

  const [loading, setLoading] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false) // new state variable

  const [successDocuments, setSuccessDocuments] = useState(false)

  const changeStateOfShowSuccessMessage = () => {
    setShowSuccessMessage(!showSuccessMessage)
  }

  const renderSuccessMessage = () => {
    if (showSuccessMessage) {
      return (
        <InstantMessageSuccess
          severity={"success"}
          title='Success'
          message={
            totalDueRefund == 0
              ? "Your policy has been amended successfully and your documents are being emailed to you."
              : "Successfully issued refund."
          }
        />
      )
    }
  }

  async function getMtaCalculate() {
    try {
      const actionPath = isRenewal ? "renewals" : "mta"
      setLoading(true)
      const API_ENDPOINT = process.env.REACT_APP_API_URL

      // Create a new object excluding userInfo and policyStartDate because of some date filters on backend
      const { userInfo, policyStartDate, ...filteredData } = customerPortalData

      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/lansdown/${actionPath}/calculate`,
        filteredData
      )
      const data = response && response.data
      setActivityQuoteResponse(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      alert("Something went wrong. Please try again later.")
      throw error
    }
  }

  const handleConfirm = async () => {
    try {
      setLoadingConfirm(true)
      const API_ENDPOINT = process.env.REACT_APP_API_URL
      const customerPortalDataWithQuoteId = {
        ...customerPortalData,
        quoteId: quoteId // new quote id from calculate Amended quote data
      }
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/lansdown/mta/confirm`,
        customerPortalDataWithQuoteId
      )
      const data = response && response.data
      setLoadingConfirm(false)
      if (response.status === 200 || response.status.text === "OK") {
        changeStateOfShowSuccessMessage()

        setTimeout(() => {
          navigate(`/portal/${token}`)
        }, 3000)
      }
    } catch (error) {
      console.log(error)
      setLoadingConfirm(false)
      alert("Something went wrong. Please try again later.", error)
      throw error
    }
  }

  const handleRefund = async () => {
    try {
      setLoadingConfirm(true)
      const API_ENDPOINT = process.env.REACT_APP_API_URL
      const dataToSend = {
        amount: Math.abs(Math.round((totalDueRefund + Number.EPSILON) * 100)),
        quoteId: quoteId, // new quote id from calculate Amended quote data
        currency: "gbp",
        edited: false,
        isMtaPolicy: true,
        stripePaymentPurpose: "QUOTE",
        admin_fee,
        agent_commission,
        agent_commission_rate,
        gross_premium,
        net_premium,
        ipt_amt,
        total,
        totalDueRefund
      }
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/payment-intent/refund`,
        dataToSend
      )
      const data = response && response.data
      setLoadingConfirm(false)
      if (response.status === 200 || response.status.text === "OK") {
        changeStateOfShowSuccessMessage()

        setTimeout(() => {
          navigate(`/portal/${token}`)
        }, 3000)
      }
    } catch (error) {
      console.log(error)
      setLoadingConfirm(false)
      alert(
        "Failed to issue a refund, please contact Administrator",
        error.message
      )
      throw error
    }
  }

  console.log(totalDueRefund, "totalDueRefund")

  const handleConfirmButton = () => {
    if (isRenewal) {
      setShowStripe(true)
    } else {
      if (totalDueRefund === 0) {
        handleConfirm()
      } else if (totalDueRefund < 0) {
        handleRefund()
      } else {
        setShowStripe(true)
      }
    }
  }

  const renderTotalDue = () => {
    if (totalDueRefund == 0) {
      return "£" + Number(Math.abs(totalDueRefund)).toFixed(2)
    } else if (totalDueRefund < 0) {
      return "- £" + Number(Math.abs(totalDueRefund)).toFixed(2)
    } else {
      return "£" + Number(Math.abs(totalDueRefund)).toFixed(2)
    }
  }

  useEffect(() => {
    console.log("useEffect")

    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    getMtaCalculate()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <div className='public-liabliliy-container'>
        {renderSuccessMessage()}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='quote-container'>
            <div className='cost-container'>
              <Typography variant='h5' className='text'>
                Your Quote
              </Typography>
            </div>

            {/* TODO: replace data with props from previous pages or from API data */}
            <div className='horizontal-display'>
              <Typography variant='body2' className='text tax'>
                Public Liability Cover Level {"£" + pl_cover_amt + "m"}
              </Typography>
            </div>

            <div className='horizontal-display'>
              <Typography variant='body2' className='text tax'>
                Turnover{" "}
                {" " +
                  "£" +
                  Number(parseFloat(pl_turnover).toFixed(2)).toLocaleString(
                    "en"
                  )}
              </Typography>
            </div>

            {el_wageroll ? (
              <div className='horizontal-display'>
                <Typography variant='body2' className='text tax'>
                  Employers liability Wageroll{" "}
                  {" " +
                    "£" +
                    Number(parseFloat(el_wageroll).toFixed(2)).toLocaleString(
                      "en"
                    )}
                </Typography>
              </div>
            ) : null}

            {equipment_cover_amt ? (
              <div className='horizontal-display'>
                <Typography variant='body2' className='text tax'>
                  Equipment cover level
                  {" " +
                    "£" +
                    Number(
                      parseFloat(equipment_cover_amt).toFixed(2)
                    ).toLocaleString("en")}
                </Typography>
              </div>
            ) : null}

            <div className='horizontal-display'>
              <Typography variant='body2' className='text tax font-bold'>
                Insurance Premium Tax
              </Typography>
              <Typography variant='body2' className='text tax font-bold'>
                {"£" + Number(ipt_amt).toFixed(2)}
              </Typography>
            </div>
            <div className='horizontal-display' style={{ paddingTop: "1rem" }}>
              <Typography variant='h5' className='text tax'>
                Total Premium
              </Typography>
              <Typography variant='h5' className='text tax'>
                {"£" + Number(total).toFixed(2)}
              </Typography>
            </div>
            <div className='horizontal-display' style={{ paddingTop: "1rem" }}>
              <Typography variant='h5' className='text tax'>
                Premium Adjusted (pro rata)
              </Typography>

              <Typography variant='h5' className='text tax'>
                {"£" + Number(premiumAdjustment).toFixed(2)}
              </Typography>
            </div>
            <div className='horizontal-display' style={{ paddingTop: "1rem" }}>
              <Typography variant='h5' className='text tax'>
                Total due/Refund
              </Typography>
              <Typography variant='h5' className='text tax'>
                {renderTotalDue()}
              </Typography>
            </div>
          </div>
        </Grid>
        {/* end of quote container */}

        <Grid item xs={12} sm={12}>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem" }}
          >
            Thank you for your quotation request.
          </Typography>
          <Typography variant='body1' className='text'>
            We have emailed the quotation documents to you which includes all of
            the terms and conditions.
          </Typography>
          <Typography
            variant='h5'
            className='text'
            style={{ paddingBottom: "0", paddingTop: "1rem" }}
          >
            Quote Confirmation
          </Typography>

          <div className='' style={{ paddingBottom: "2rem" }}>
            {/* TODO: replace data with props from previous pages or from API data */}
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Policyholder</Typography>
              <Typography variant='body1'>
                {organisationFirstName + " " + organisationLastName}
              </Typography>
            </div>
            <Divider />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Business name</Typography>
              <Typography variant='body1'>{organisationName}</Typography>
            </div>
            <Divider />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Email</Typography>
              <Typography variant='body1'>{emailAddress}</Typography>
            </div>
            <Divider orientation='horizontal' />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Phone</Typography>
              <Typography variant='body1'>{phone}</Typography>
            </div>
            <Divider orientation='horizontal' />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Address</Typography>
              <Typography variant='body1'>{address1}</Typography>
            </div>

            <div className='horizontal-display'>
              <Typography variant='body1'></Typography>
              <Typography variant='body1'>{address4}</Typography>
            </div>

            <div className='horizontal-display'>
              <Typography variant='body1'></Typography>
              <Typography variant='body1'>{postcode.toUpperCase()}</Typography>
            </div>
            <Divider orientation='horizontal' />
          </div>

          <div
            className={!showStripe ? "button-container" : "button-hide"}
            style={{ justifyContent: "space-between" }}
          >
            <Button
              sx={{ color: "#fff", width: "auto" }}
              type='submit'
              size='large'
              variant='contained'
              color='primary'
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Modify Policy
            </Button>
            <LoadingButton
              loading={loadingConfirm}
              size='large'
              sx={{ color: "#fff", width: "auto" }}
              variant='contained'
              onClick={handleConfirmButton}
              endIcon={<ArrowForwardIosIcon />}
            >
              {isRenewal ? "Confirm Renewal" : "Confirm Policy"}
            </LoadingButton>
          </div>
          {showStripe && (
            <div className='stripe-container'>
              <Elements stripe={stripePromise}>
                <StripePaymentPortal />
              </Elements>
            </div>
          )}
        </Grid>
      </div>
    </ThemeProvider>
  )
}
