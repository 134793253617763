// src/useAnalytics.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export function useAnalytics(trackingId) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(trackingId);
  }, [trackingId]);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.send("page_view", { page_path: currentPath });
  }, [location]);

  if (location.pathname === "/") {
    return "Home"
  }

  return location.pathname
}
