import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import Typography from "@mui/material/Typography"
import "./equipment.css"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import Switch from "@mui/material/Switch"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { useAIForm } from "../../../../../contexts/ActivityInsuranceFormContext"
import useStyles from "../../styles"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Tooltip from "@mui/material/Tooltip"
import { display } from "@mui/system"

// tootilp text
const tooltipTitle = () => {
  return (
    <>
      <Typography variant='body2'>
        {`Provides cover for activity equipment that you specifically use to carry out the activities for which you are insured.   Accidental damage while equipment is in use is also not covered.`}
      </Typography>
      <br />
      <Typography variant='body2'>{`If this equipment was damaged or was lost during the period of cover, the insurer will cover the cost of repair or replacement of that item, up to the limit shown in the schedule.`}</Typography>
      <br />
      <Typography variant='body2'>{`This option does not cover any electrical items such as iPads, iPhones, Laptops, Sound Systems, Speaker or similar. Accidental damage while equipment is in use is also not covered.`}</Typography>
      <br />
      <Typography variant='body2'>{`Please be aware that the equipment cover will not extend to cover buildings or outbuildings such as sheds or cabins.`}</Typography>
    </>
  )
}

const ERRORS = {
  equipment: "",
  equipment_cover_amt: ""
}
let debounceTimeoutId

export default function EquipmentPortal() {
  const { aiFormData, updateAIFormFields } = useAIForm()
  const { customerPortalData } = aiFormData // new object in context\
  let { equipment = false, equipment_cover_amt = "" } = customerPortalData || {}

  const [errors, setErrors] = useState(ERRORS)
  const [loading, setLoading] = useState(false)
  const [isQuickQuoteApiCalled, setIsQuickQuoteApiCalled] = useState(false)

  function handleEquipmentChange(event) {
    const newEquipmentState = event.target.checked
    setErrors((p) => {
      return { ...p, equipment: "" }
    })

    updateAIFormFields({
      customerPortalData: {
        ...customerPortalData,
        equipment: newEquipmentState,
        equipment_cover_amt: newEquipmentState ? equipment_cover_amt : ""
      }
    })

    if (!newEquipmentState) {
      setErrors((p) => {
        return { ...p, equipment_cover_amt: "" }
      })
    } else {
      validateEquipmentCover(equipment_cover_amt)
    }
  }

  function handleEquipmentCoverChange(event) {
    setErrors((p) => {
      return { ...p, equipment_cover_amt: "" }
    })
    /*   updateAIFormFields({
      equipment_cover_amt: event.target.value,
      equipment: true
    })
    
 */

    updateAIFormFields({
      customerPortalData: {
        ...customerPortalData,
        equipment_cover_amt: event.target.value,
        equipment: true
      }
    })

    validateEquipmentCover(event.target.value)
  }

  // validations
  function validateEquipmentCover(ec) {
    if (equipment) {
      if (ec === "") {
        setErrors((p) => {
          return { ...p, equipment_cover_amt: "Please choose the amount" }
        })
        return false
      }
      return true
    }
  }

  function validateAll() {
    if (equipment) {
      let equipmentCoverIsValid = validateEquipmentCover(equipment_cover_amt)
      return equipmentCoverIsValid
    } else if (!equipment) {
      let equipmentCoverIsValid = validateEquipmentCover(equipment_cover_amt)
      return !equipmentCoverIsValid
    } else {
      let equipmentCoverIsValid = validateEquipmentCover(equipment_cover_amt)
      return equipmentCoverIsValid
    }
  }

  async function checkQuote() {
    const isValid = validateAll()
    if (!isValid) return
    /*     try {
      setLoading(true)
      await fetchQuickQuoteFromAPI()
      setIsQuickQuoteApiCalled(true)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => setLoading(false), 1000)
    } */
  }

  /*   function handleNext() {
    const isValid = validateAll()
    if (isValid) next()
  } */

  useEffect(() => {
    checkQuote()
  }, [equipment, equipment_cover_amt])

  return (
    <div className='equipment-page'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div className='tooltip'>
            <Typography variant='h6' className='public-liability text'>
              Equipment
            </Typography>
            <Tooltip
              title={tooltipTitle()}
              placement='left-start'
              enterTouchDelay={0}
            >
              <InfoOutlinedIcon
                color='primary'
                fontSize='large'
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>

          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0" }}
          >
            Do you want to cover your equipment?
          </Typography>
          <FormControlLabel
            control={
              <Switch
                className='switch'
                checked={equipment}
                onChange={handleEquipmentChange}
                name='equipment'
              />
            }
            label='Equipment cover required?'
          />
          <Typography variant='body1' className='text'>
            Choose the amount of equipment cover you require shown in £1,000
            increments up to £20,000
          </Typography>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant='standard'
              fullWidth
              error={!!errors.equipment_cover_amt}
            >
              <InputLabel id='demo-simple-select-standard-label'>
                Equipment cover
              </InputLabel>
              <Select
                labelId='demo-simple-select-standard-label'
                id='demo-simple-select-standard'
                value={equipment_cover_amt}
                onChange={handleEquipmentCoverChange}
                label='Equipment cover'
              >
                <MenuItem value={1000}>£1,000</MenuItem>
                <MenuItem value={2000}>£2,000</MenuItem>
                <MenuItem value={3000}>£3,000</MenuItem>
                <MenuItem value={4000}>£4,000</MenuItem>
                <MenuItem value={5000}>£5,000</MenuItem>
                <MenuItem value={6000}>£6,000</MenuItem>
                <MenuItem value={7000}>£7,000</MenuItem>
                <MenuItem value={8000}>£8,000</MenuItem>
                <MenuItem value={9000}>£9,000</MenuItem>
                <MenuItem value={10000}>£10,000</MenuItem>
                <MenuItem value={10000}>£10,000</MenuItem>
                <MenuItem value={11000}>£11,000</MenuItem>
                <MenuItem value={12000}>£12,000</MenuItem>
                <MenuItem value={13000}>£13,000</MenuItem>
                <MenuItem value={14000}>£14,000</MenuItem>
                <MenuItem value={15000}>£15,000</MenuItem>
                <MenuItem value={16000}>£16,000</MenuItem>
                <MenuItem value={17000}>£17,000</MenuItem>
                <MenuItem value={18000}>£18,000</MenuItem>
                <MenuItem value={19000}>£19,000</MenuItem>
                <MenuItem value={20000}>£20,000</MenuItem>
              </Select>
            </FormControl>
            {errors.equipment_cover_amt && (
              <FormHelperText error>
                {errors.equipment_cover_amt}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
