import React, { useState, createContext, useContext } from "react"
import axios from "axios"

const steps = 8

const VALIDATION_REGEX = {
  NUMERIC_STRING: `^(\\s*|\\d+)$`
}

const AIFormContext = createContext()

export function useAIForm() {
  return useContext(AIFormContext)
}

export function InsuranceFormProvider({ children }) {
  const [aiFormData, setAIFormData] = useState({})
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [activityQuoteResponse, setActivityQuoteResponse] = useState([{}])

  function updateAIFormFields(fields) {
    setAIFormData((prev) => {
      return { ...prev, ...fields }
    })
  }

  function next() {
    setCurrentStepIndex((i) => {
      if (i >= steps) return i
      return i + 1
    })
  }

  function goToDesiredStep(step) {
    setCurrentStepIndex(step)
  }

  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 0) return i
      return i - 1
    })
  }

  async function fetchQuickQuoteFromAPI() {
    try {
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/lansdown/quickquote`,
        aiFormData
      )
      const data = response && response.data
      setActivityQuoteResponse(data)
    } catch (error) {
      console.log(`POST /api/v1/lansdown/quickquote | ERROR: ${error}`)
    }
  }

  return (
    <AIFormContext.Provider
      value={{
        aiFormData,
        updateAIFormFields,
        currentStepIndex,
        goToDesiredStep,
        next,
        back,
        activityQuoteResponse,
        setActivityQuoteResponse,
        VALIDATION_REGEX,
        fetchQuickQuoteFromAPI
      }}
    >
      {children}
    </AIFormContext.Provider>
  )
}
