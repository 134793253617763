import React, { useEffect } from 'react';
import MaterialLayout from "./components/Layout/MaterialLayout"
import { DeviceProvider } from "./contexts/DeviceContext"
import { InsuranceFormProvider } from "./contexts/ActivityInsuranceFormContext"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Routes, Route, useLocation } from "react-router-dom"
import CheckoutPage from "./components/CheckoutPage"
import CustomerPortal from "./components/Pages/CustomerPortal/CustomerPortal"
import HeaderComponent from "./components/Header/HeaderComponent"
import ErrorPage from "./components/Pages/ErrorPage/ErrorPage"
import CustomerPanel from "./components/Pages/CustomerPortal/CustomerPanel";
import CustomerPortalJourney from "./components/Pages/CustomerPortal/CustomerPortalJourney";
import CustomerPortalPolicySummary from "./components/Pages/CustomerPortal/CustomerPortalPolicySummary";
import ReactGA from "react-ga4";
import { useAnalytics } from "./useAnaytics";




const theme = createTheme({
  palette: {
    primary: {
      main: "#41BAAE",
      contrastText: "#fff"
    }
  }
})

function App() {
  const trackingId = "UA-123332944-2";

  useAnalytics(trackingId); // Use the custom hook


  return (
    <ThemeProvider theme={theme}>
      <DeviceProvider>
        <InsuranceFormProvider>
          <MaterialLayout>
            <HeaderComponent />
            <Routes>
              <Route path='/' element={<CheckoutPage />} />
              <Route path='/customer-login' element={<CustomerPortal />} />
              <Route path="/portal/:token" element={<CustomerPanel />} />
              <Route path='/portal/:token/update-policy/:policyNumberFromPortal' element={<CustomerPortalJourney />} />
              <Route path='/portal/:token/update-policy/:policyNumberFromPortal/policy-summary' element={<CustomerPortalPolicySummary />} />

              <Route path='/portal/:token/renew-policy/:policyNumberFromPortal' element={<CustomerPortalJourney />} />
              <Route path='/portal/:token/renew-policy/:policyNumberFromPortal/policy-summary' element={<CustomerPortalPolicySummary />} />
              <Route path='/portal/:token/renew-policy-from-email/:policyNumberFromPortal' element={<CustomerPortalJourney />} />
              <Route path='/portal/:token/renew-policy-from-email/:policyNumberFromPortal/policy-summary' element={<CustomerPortalPolicySummary />} />


              {/* <Route path='*' element={<ErrorPage />} /> */}

            </Routes>
          </MaterialLayout>
        </InsuranceFormProvider>
      </DeviceProvider>
    </ThemeProvider>
  )
}

export default App;
