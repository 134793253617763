import React, { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import axios from "axios"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import "./customerPortal.css"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import DateOfChange from "./components/DateOfChange/DateOfChange"
import ActivitiesPortal from "./components/Activities/ActivitiesPortal"
import PublicLiabilityPortal from "./components/PublicLibility/PublicLiabilityPortal"
import EmployersLiabilityPortal from "./components/EmployersLiablityPortal/EmployersLiabilityPortal"
import EquipmentPortal from "./components/EquipmentPortal/EquipmentPortal"
import AddressFormPortal from "./components/AddressFormPortal/AddressFormPortal"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import dayjs from "dayjs"

// context
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import { useDevice } from "../../../contexts/DeviceContext"
import Button from "@mui/material/Button"

const CustomerPortalJourney = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [loading, setLoading] = useState(true)

  const { policyNumberFromPortal, token } = useParams()

  const { aiFormData, updateAIFormFields } = useAIForm()

  const { customerPortalData = {} } = aiFormData // new object in context\
  const { userInfo } = customerPortalData

  const getActionType = () => {
    if (location.pathname.includes("renew-policy")) {
      return "renewals"
    } else if (location.pathname.includes("from-email")) {
      return "renewals-from-email"
    } else {
      return "mta"
    }
  }

  const actionType = getActionType()

  // Set the page title based on the action type
  const pageTitle =
    actionType === "renewals" || actionType === "renewals-from-email"
      ? "Renewal"
      : "Mid-term adjustment"

  async function getPolicyById(policyNumberFromPortal) {
    try {
      const API_ENDPOINT = process.env.REACT_APP_API_URL
      let apiUrl = `${API_ENDPOINT}/api/v1/lansdown/` // Base URL

      // Dynamically set the specific endpoint based on the actionType
      switch (actionType) {
        case "renewals":
          apiUrl += `renewalsQuoteId?quoteId=${policyNumberFromPortal}`
          break
        case "renewals-from-email":
          apiUrl += `renewalsQuoteId?quoteId=${policyNumberFromPortal}`
          break
        case "mta":
          apiUrl += `mta?policyNumber=${policyNumberFromPortal}`
          break
        default:
          throw new Error("Unknown action type")
      }

      const response = await axios.get(apiUrl)

      if (response.status === 200) {
        setLoading(false)
        updateAIFormFields({
          ...aiFormData,
          customerPortalData: {
            ...aiFormData.customerPortalData,
            ...response.data,
            policyStartDate: dayjs(new Date()).format("DD-MM-YYYY")
          }
        })
      }
    } catch (error) {
      console.log(error)
      // Handle the error appropriately
    }
  }

  const handleNext = () => {
    console.log("Current Path:", location.pathname)
    const actionType = getActionType()
    const actionPath =
      actionType === "renewals" ? "renew-policy" : "update-policy"

    navigate(
      `/portal/${token}/${actionPath}/${policyNumberFromPortal}/policy-summary`
    )
  }

  useEffect(() => {
    getPolicyById(policyNumberFromPortal)
  }, [policyNumberFromPortal, location.pathname])

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <div className=''>
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className='title-container' style={{ marginTop: "1rem" }}>
              <Typography
                variant='h6'
                className='text'
                sx={{ padding: "0", color: "#fff" }}
              >
                {`${pageTitle} Policy Id - ${
                  actionType === "mta"
                    ? policyNumberFromPortal
                    : customerPortalData?.policyNumber
                }`}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <DateOfChange />
            </Grid>
            <Divider
              style={{
                boxShadow: `rgb(65, 186, 174) 0px 0px 2px 2px`,
                marginTop: "1rem",
                marginBottom: "1rem"
              }}
            />
            <Grid item xs={12} sm={12} style={{ paddingTop: "2.5rem" }}>
              <ActivitiesPortal />
            </Grid>
            <Divider
              style={{
                boxShadow: `rgb(65, 186, 174) 0px 0px 2px 2px`,
                marginTop: "1rem",
                marginBottom: "1rem"
              }}
            />
            <Grid item xs={12} sm={12} style={{ paddingTop: "2.5rem" }}>
              <PublicLiabilityPortal />
            </Grid>
            <Divider
              style={{
                boxShadow: `rgb(65, 186, 174) 0px 0px 2px 2px`,
                marginTop: "1rem",
                marginBottom: "1rem"
              }}
            />
            <Grid item xs={12} sm={12} style={{ paddingTop: "2.5rem" }}>
              <EmployersLiabilityPortal />
            </Grid>
            <Divider
              style={{
                boxShadow: `rgb(65, 186, 174) 0px 0px 2px 2px`,
                marginTop: "1rem",
                marginBottom: "1rem"
              }}
            />
            <Grid item xs={12} sm={12} style={{ paddingTop: "2.5rem" }}>
              <EquipmentPortal />
            </Grid>
            <Divider
              style={{
                boxShadow: `rgb(65, 186, 174) 0px 0px 2px 2px`,
                marginTop: "1rem",
                marginBottom: "1rem"
              }}
            />

            <Grid item xs={12} sm={12} style={{ paddingTop: "2.5rem" }}>
              <AddressFormPortal />
            </Grid>
            <Divider
              style={{
                boxShadow: `rgb(65, 186, 174) 0px 0px 2px 2px`,
                marginTop: "1rem",
                marginBottom: "2rem"
              }}
            />

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                whiteSpace: "nowrap"
              }}
            >
              <Grid item>
                <Button
                  onClick={handleNext}
                  size='large'
                  variant='contained'
                  color='primary'
                  endIcon={<ArrowForwardIcon />}
                  style={{ width: "auto" }}
                >
                  GET UPDATED QUOTE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </div>
  )
}

export default CustomerPortalJourney
