import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import Typography from "@mui/material/Typography"
import "./employersliability.css"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { useAIForm } from "../../../../../contexts/ActivityInsuranceFormContext"
import useStyles from "../../styles"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { isValid } from "date-fns"
import { NumericFormat } from "react-number-format"
import OutlinedInput from "@mui/material/OutlinedInput"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Tooltip from "@mui/material/Tooltip"
import { display } from "@mui/system"

// modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "10px",
  bgcolor: "background.paper",
  border: "2px solid #41BAAE",
  boxShadow: 24,
  p: 4
}

// tootilp text
const tooltipTitle = () => {
  return (
    <>
      <Typography variant='body2'>
        {`You need this cover by law if you own a business and employ other people.`}
      </Typography>
      <br />
      <Typography variant='body2'>
        {`This cover will protect you if one of your employees claims against you for an injury or illness that they may have they suffered while working for you, if they felt this is down to your negligence.`}
      </Typography>
      <br />
      <Typography variant='body2'>
        {`Please note you will still require Employers’ Liability for volunteer staff.`}
      </Typography>
    </>
  )
}

const ERRORS = {
  el: "",
  el_wageroll: "",
  el_volunteer_staff: ""
}
const NUMERIC_STRING_REGEX = `[0-9]+(,[0-9]+)*`
let debounceTimeoutId

export default function EmployersLiabilityPortal() {
  const classes = useStyles()

  const { aiFormData, updateAIFormFields } = useAIForm()
  const { customerPortalData } = aiFormData // new object in context\
  let { el = false, el_wageroll = "", el_volunteer_staff = false } =
    customerPortalData || {}

  const [errors, setErrors] = useState(ERRORS)
  const [loading, setLoading] = useState(false)
  let [ElFEdisplay, setElFEdisplay] = useState("") // for FE display only (with commas)

  //modal
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)

  const handleClose = (event, reason) => {
    // prevent closing on backdrop click
    if (reason !== "backdropClick") {
      updateAIFormFields({
        el_wageroll: "",
        el_volunteer_staff: false,
        el: false
      })
      setElFEdisplay("")
      setOpen(false)
    }

    // set the wageroll to "" in customerPortalData
    updateAIFormFields({
      customerPortalData: {
        ...customerPortalData,
        el_wageroll: ""
      }
    })
  }

  const handleEmployersLiabilityChange = (event) => {
    const value = event.target.checked
    updateAIFormFields({
      customerPortalData: {
        ...customerPortalData,
        el: value
      }
    })

    // if el is false, set the wageroll to "" in customerPortalData and vice versa
    if (!value) {
      updateAIFormFields({
        customerPortalData: {
          ...customerPortalData,
          el_wageroll: "",
          el: value,
          el_volunteer_staff: false
        }
      })
    } else {
      updateAIFormFields({
        customerPortalData: {
          ...customerPortalData,
          el_wageroll: el_wageroll,
          el: value
        }
      })
    }

    if (!value) {
      setErrors((p) => ({
        ...p,
        el_wageroll: "",
        el_volunteer_staff: ""
      }))
    }
  }

  // validations
  function validateWageroll(wageroll) {
    if (!el) {
      setErrors((p) => ({ ...p, el_wageroll: "" }))
      return
    }

    if (wageroll === "" || wageroll === "0") {
      setErrors((p) => ({
        ...p,
        el_wageroll: "Please enter your estimated annual wageroll"
      }))
      return
    }
    if (!wageroll.toString().match(NUMERIC_STRING_REGEX)) {
      setErrors((p) => {
        return { ...p, el_wageroll: "Must be a number" }
      })
      return
    }
    setErrors((p) => ({ ...p, el_wageroll: "" }))
  }

  const handleVolunteerStuffChange = (event) => {
    const value = event.target.checked
    updateAIFormFields({
      customerPortalData: {
        ...customerPortalData,
        el_volunteer_staff: value
      }
    })

    // if el_volunteer_staff is true, set the el to tru in customerPortalData and vice versa
    if (value) {
      updateAIFormFields({
        customerPortalData: {
          ...customerPortalData,
          el: true,
          el_volunteer_staff: value
        }
      })
    } else {
      updateAIFormFields({
        customerPortalData: {
          ...customerPortalData,
          el: false,
          el_wageroll: "",
          el_volunteer_staff: value
        }
      })
    }

    if (value) {
      setErrors((p) => ({ ...p, el_wageroll: "" }))
    } else {
      validateWageroll(el_wageroll)
    }
  }

  useEffect(() => {
    if (el) {
      validateWageroll(el_wageroll)
    }
  }, [el])

  // validations
  function validateWageroll(wageroll) {
    if (!el) {
      setErrors((p) => ({ ...p, el_wageroll: "" }))
      return
    }

    if (wageroll === "" || wageroll === "0") {
      setErrors((p) => ({
        ...p,
        el_wageroll: "Please enter your estimated annual wageroll"
      }))
      return
    }
    if (!wageroll.toString().match(NUMERIC_STRING_REGEX)) {
      setErrors((p) => {
        return { ...p, el_wageroll: "Must be a number" }
      })
      return
    }
    setErrors((p) => ({ ...p, el_wageroll: "" }))
  }

  function validateAll() {
    if (el) {
      let wagerollIsValid = validateWageroll(el_wageroll)
      return wagerollIsValid
    } else if (!el) {
      let wagerollIsValid = validateWageroll(el_wageroll)
      return !wagerollIsValid
    } else {
      let wagerollIsValid = validateWageroll(el_wageroll)
      return wagerollIsValid
    }
  }

  const handleWagerollChange = (event) => {
    const value = event.target.value
    const wagerollValue = value.replace(/,/g, "") // Remove commas for storing in the state

    setElFEdisplay(value) // For FE display with commas
    updateAIFormFields({
      customerPortalData: {
        ...customerPortalData,
        el_wageroll: wagerollValue
      }
    })

    // Validate the wageroll after a debounce
    clearTimeout(debounceTimeoutId)
    debounceTimeoutId = setTimeout(() => {
      validateWageroll(wagerollValue)
    }, 500)
  }

  /*   if (el_wageroll !== "") {
    el = true
  }

  if (el_volunteer_staff) {
    el = true
  } */

  /*  function handleNext() {
    const isValid = validateAll()
    if (isValid) next()
  }
 */

  // modal
  useEffect(() => {
    if (el_wageroll > 50000) {
      setOpen(true)
    }
  }, [el_wageroll])

  return (
    <div className='public-liabliliy-container'>
      <Modal
        disableEscapeKeyDown={true}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h5'
            component='h2'
            sx={{ textAlign: "center", color: "#41BAAE" }}
          >
            Activity Business Cover
          </Typography>
          <Typography
            id='modal-modal-description'
            variant='body1'
            sx={{ mt: 4 }}
          >
            {`Your business is outside of our online limits because of the Wages paid being over £50,000`}
          </Typography>
          <Typography
            id='modal-modal-description'
            variant='body1'
            sx={{ mt: 4 }}
          >
            Please call us on 0207 078 7540 to speak with an advisor.
          </Typography>
          <div className='center'>
            <Button variant='contained' sx={{ mt: 4 }} onClick={handleClose}>
              CONFIRM
            </Button>
          </div>
        </Box>
      </Modal>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div className='tooltip'>
            <Typography variant='h6' className='public-liability text'>
              Employers’ Liability
            </Typography>

            <Tooltip
              title={tooltipTitle()}
              placement='left-start'
              enterTouchDelay={0}
            >
              <InfoOutlinedIcon
                color='primary'
                fontSize='large'
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0" }}
          >
            Do you need Employers’ Liability Insurance?
          </Typography>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0" }}
          >
            <span className='italic-paragraf'>
              If you have any employees or volunteer staff you will require EL
              cover{" "}
            </span>
          </Typography>
          <FormControlLabel
            className=''
            control={
              <Switch
                className='switch'
                checked={!!el}
                onChange={handleEmployersLiabilityChange}
                name='liability'
              />
            }
            label='Employers’ Liability Required'
          />
          <Typography variant='body1' className='text'>
            Please enter your estimated annual wageroll
          </Typography>
          <Grid item xs={12} sm={6}>
            <NumericFormat
              customInput={Input}
              startAdornment={
                <InputAdornment position='start'>£</InputAdornment>
              }
              error={!!errors.el_wageroll}
              value={el_wageroll}
              onChange={handleWagerollChange}
              id='standard-basic'
              label='Wageroll'
              variant='standard'
              fullWidth
              thousandsGroupStyle='thousand'
              thousandSeparator=','
            />
            {errors.el_wageroll && (
              <FormHelperText error>{errors.el_wageroll}</FormHelperText>
            )}
          </Grid>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0" }}
          >
            Are some, or all of your staff volunteers?
          </Typography>
          <FormControlLabel
            control={
              <Switch
                className='switch'
                checked={!!el_volunteer_staff}
                onChange={handleVolunteerStuffChange}
                name='volunteer'
              />
            }
            label='Volunteer staff'
          />
        </Grid>
      </Grid>
    </div>
  )
}
