import React from "react"
import { useState } from "react"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import "./customerPortal.css"
import Divider from "@mui/material/Divider"
import { createTheme } from "@material-ui/core/styles"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import SendIcon from "@mui/icons-material/Send"
import axios from "axios"
import LoadingButton from "@mui/lab/LoadingButton"
import { width } from "@mui/system"
import InstantMessageSuccess from "../Alerts/InstantMessageSuccess"

import Modal from "@mui/material/Modal"

const theme = createTheme({
  palette: {
    primary: {
      main: "#41BAAE",
      contrastText: "#fff"
    }
  }
})

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
}

function CustomerPortal() {
  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const ERRORS = {
    email: ""
  }

  const [errors, setErrors] = useState(ERRORS)
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const RenderModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Something went wrong
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Most likely the email you entered is not registered with us. Please
            try again.
          </Typography>
        </Box>
      </Modal>
    )
  }

  // handle email
  const handleEmail = (event) => {
    setErrors((p) => {
      return { ...p, email: "" }
    })
    setEmail(event.target.value)
    validateEmail(event.target.value)
  }

  // validate email
  function validateEmail(email) {
    if (email === "") {
      setErrors((p) => {
        return { ...p, email: "Please enter your email" }
      })
      return false
    }
    if (!email.match(EMAIL_REGEX)) {
      setErrors((p) => {
        return { ...p, email: "Please enter a valid email" }
      })
      return false
    }
    return true
  }

  // is form valid
  function isFormValid() {
    let isValid = true
    if (!validateEmail(email)) {
      isValid = false
    }

    return isValid
  }

  // handle submit
  async function handleNext() {
    const isValid = isFormValid()
    if (isValid) {
      try {
        setLoading(true)
        sendUrlToEmail(email)
      } catch {}
    }
  }

  async function sendUrlToEmail(email) {
    try {
      setEmail("")
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.get(
        `${API_ENDPOINT}/api/v1/policy-holder-auth/send-portal-access?email=${email}`
      )
      const data = response && response.data && response.data.content

      if (response.status === 200) {
        setSuccess(true)
        setLoading(false)
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      handleOpen(true)
    }
  }

  return (
    <div className='customer-portal-container'>
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className='title-container' style={{ marginTop: "1rem" }}>
              <Typography
                variant='h5'
                className='text'
                sx={{ padding: "0", color: "#fff" }}
              >
                Customer Portal Login
              </Typography>
            </div>
          </Grid>
          <RenderModal />
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              variant='body1'
              gutterBottom
              className='text'
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
            >
              Please enter your email to get a link for the new login session
            </Typography>
            <Box
              sx={{
                maxWidth: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem"
              }}
            >
              <TextField
                error={!!errors.email}
                helperText={errors.email}
                value={email}
                onChange={handleEmail}
                required
                id='email'
                label='Email'
                fullWidth
              />
              <LoadingButton
                /*    onMouseEnter={() => {
                  setSuccess(false)
                  console.log(success)
                }} */
                loading={loading}
                onClick={handleNext}
                variant='contained'
                endIcon={<SendIcon />}
                size='large'
                sx={{
                  marginBottom: "0",
                  justifyItems: "revert",
                  alignSelf: "start",
                  height: "48px",
                  paddingLeft: { xs: "1.5rem", sm: "2rem", md: "2rem" },
                  paddingRight: { xs: "1.5rem", sm: "2rem", md: "2rem" }
                }}
              >
                Send
              </LoadingButton>
            </Box>
            {success ? (
              <InstantMessageSuccess
                title={"Success"}
                message={
                  "Email has been sent successfully please check your inbox"
                }
                severity='success'
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </>
    </div>
  )
}

export default CustomerPortal
