import React, { useState, createContext, useContext, useEffect } from "react"

const DeviceContext = createContext()

export function useDevice() {
  return useContext(DeviceContext)
}

export function DeviceProvider({ children }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const isMobileApp = screenWidth < 932
  const isWebApp = !isMobileApp

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  return (
    <DeviceContext.Provider value={{ isMobileApp, isWebApp }}>
      {children}
    </DeviceContext.Provider>
  )
}
