import React, { useState, useEffect } from "react"
import Typography from "@mui/material/Typography"
import "./activities.css"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"
import axios from "axios"
import CircularProgress from "@mui/material/CircularProgress"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import { useDevice } from "../../../contexts/DeviceContext"
import useStyles from "../styles"
import Grid from "@mui/material/Grid"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350
    }
  }
}

export default function Activities() {
  const classes = useStyles()
  const [activitiesData, setActivitiesData] = useState([])
  const [loading, setLoading] = useState(true)
  const { aiFormData, updateAIFormFields, next } = useAIForm()
  const { activityTrades = [] } = aiFormData
  const [error, setError] = useState("")
  const { isMobileApp, isWebApp } = useDevice()

  const handleChange = (event) => {
    setError("")
    const {
      target: { value }
    } = event
    updateAIFormFields({
      activityTrades: typeof value === "string" ? value.split(",") : value
    })
  }

  function handleNext() {
    const isValid = validate()
    if (isValid) next()
  }

  function validate() {
    if (activityTrades.length === 0) {
      setError("Please select at least one activity")
      return false
    }
    return true
  }

  async function fetchActivitiesDataFromAPI() {
    try {
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.get(
        `${API_ENDPOINT}/api/v1/activity/trade/all?limit=200`
      )
      const data = response && response.data && response.data.content
      setActivitiesData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchActivitiesDataFromAPI()
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Typography variant='h5' gutterBottom className='text'>
              Choose your Activities
            </Typography>
            <Typography variant='body1' gutterBottom className='text'>
              Please select all of the activities that you instruct
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <FormControl
                sx={{ width: isWebApp ? "50%" : "100%" }}
                error={!!error}
              >
                <InputLabel
                  name='activityNames'
                  label='Activities*'
                  id='demo-multiple-chip-label'
                >
                  Activities
                </InputLabel>
                <Select
                  labelId='demo-multiple-chip-label'
                  id='demo-multiple-chip'
                  multiple
                  fullWidth
                  value={activityTrades}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id='select-multiple-chip'
                      label='Activities'
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {activitiesData.map((activityData) => (
                    <MenuItem key={activityData.id} value={activityData.name}>
                      {activityData.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {error && <FormHelperText error>{error}</FormHelperText>}
            <div className={classes.buttons}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%"
                }}
              >
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  size='medium'
                  className={classes.button}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Box>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  )
}
