import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import Typography from "@mui/material/Typography"
import "./employersliability.css"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import useStyles from "../styles"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { isValid } from "date-fns"
import { NumericFormat } from "react-number-format"
import OutlinedInput from "@mui/material/OutlinedInput"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Tooltip from "@mui/material/Tooltip"
import { display } from "@mui/system"

// modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "10px",
  bgcolor: "background.paper",
  border: "2px solid #41BAAE",
  boxShadow: 24,
  p: 4
}

// tootilp text
const tooltipTitle = () => {
  return (
    <>
      <Typography variant='body2'>
        {`You need this cover by law if you own a business and employ other people.`}
      </Typography>
      <br />
      <Typography variant='body2'>
        {`This cover will protect you if one of your employees claims against you for an injury or illness that they may have they suffered while working for you, if they felt this is down to your negligence.`}
      </Typography>
      <br />
      <Typography variant='body2'>
        {`Please note you will still require Employers’ Liability for volunteer staff.`}
      </Typography>
    </>
  )
}

const ERRORS = {
  el: "",
  el_wageroll: "",
  el_volunteer_staff: ""
}
const NUMERIC_STRING_REGEX = `[0-9]+(,[0-9]+)*`
let debounceTimeoutId

export default function EmployersLiability() {
  const classes = useStyles()
  const {
    aiFormData,
    updateAIFormFields,
    next,
    activityQuoteResponse,
    fetchQuickQuoteFromAPI,
    back
  } = useAIForm()
  const { total, refer, refer_reason } = activityQuoteResponse[0]
  let { el = false, el_wageroll = "", el_volunteer_staff = false } = aiFormData
  const [errors, setErrors] = useState(ERRORS)
  const [loading, setLoading] = useState(false)
  let [ElFEdisplay, setElFEdisplay] = useState("") // for FE display only (with commas)

  //modal
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)

  const handleClose = (event, reason) => {
    // prevent closing on backdrop click
    if (reason !== "backdropClick") {
      updateAIFormFields({
        el_wageroll: "",
        el_volunteer_staff: false,
        el: false
      })
      setElFEdisplay("")
      setOpen(false)
    }
  }

  // checks if the the refer is true and if so opens the modal
  const handleReferals = () => {
    if (refer && refer_reason) {
      handleOpen()
    }
  }

  // handlers
  const handleEmployersLiabilityChange = (event) => {
    // no errors if not checked
    updateAIFormFields({ el: !!event.target.checked })

    if (event.target.checked === false) {
      updateAIFormFields({ el_wageroll: "", el_volunteer_staff: false })
      setElFEdisplay("")
    }
  }

  const handleWagerollChange = (event) => {
    setErrors((p) => {
      return { ...p, el_wageroll: "" }
    })
    handleChangeElFEdisplay(event)
    updateAIFormFields({
      el_wageroll: event.target.value.replaceAll(",", ""), // remove commas for BE
      el,
      el_volunteer_staff
    })
    validateWageroll(event.target.value)
  }

  const handleVolunteerStuffChange = (event) => {
    // no errors if not checked
    updateAIFormFields({
      el_volunteer_staff: !!event.target.checked,
      el: !!event.target.checked
    })

    if (event.target.checked === false) {
      updateAIFormFields({ el_wageroll: "", el: false })
      setElFEdisplay("")
    }
  }

  // handle EL input change (with commas)
  const handleChangeElFEdisplay = (event) => {
    setElFEdisplay(event.target.value)
  }

  // validations
  function validateWageroll(wageroll) {
    if (el && el_volunteer_staff) {
      return true
    }
    if (el) {
      if (wageroll === "" || wageroll === "0") {
        setErrors((p) => ({
          ...p,
          el_wageroll: "Please enter your estimated annual wageroll"
        }))
        return false
      }
      if (!wageroll.match(NUMERIC_STRING_REGEX)) {
        setErrors((p) => {
          return { ...p, el_wageroll: "Must be a number" }
        })
        return false
      }
      return true
    }
  }

  function validateAll() {
    if (el) {
      let wagerollIsValid = validateWageroll(el_wageroll)
      return wagerollIsValid
    } else if (!el) {
      let wagerollIsValid = validateWageroll(el_wageroll)
      return !wagerollIsValid
    } else {
      let wagerollIsValid = validateWageroll(el_wageroll)
      return wagerollIsValid
    }
  }

  if (el_wageroll !== "") {
    el = true
  }

  if (el_volunteer_staff) {
    el = true
  }

  async function checkQuote() {
    const isValid = validateAll()
    if (!isValid) return
    try {
      setLoading(true)
      await fetchQuickQuoteFromAPI()
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => setLoading(false), 500)
    }
  }

  function handleNext() {
    const isValid = validateAll()
    if (isValid) next()
  }

  useEffect(() => {
    setTimeout(() => {
      checkQuote()
    }, 500)
  }, [el, el_wageroll, el_volunteer_staff])

  useEffect(() => {
    if (ElFEdisplay === "") {
      setElFEdisplay(el_wageroll) // when returning from previous page
    }
    if (refer) {
      handleReferals()
    }
  }, [activityQuoteResponse])

  const handleTotal = (total) => {
    switch (total) {
      case 0:
        return "£0.00"
      case null:
        return "£0.00"
      case undefined:
        return "£0.00"
      case NaN:
        return "£0.00"
      default:
        if (refer) {
          return "£0.00"
        }
        return `£${Number(total).toFixed(2)}`
    }
  }

  return (
    <div className='public-liabliliy-container'>
      <Modal
        disableEscapeKeyDown={true}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h5'
            component='h2'
            sx={{ textAlign: "center", color: "#41BAAE" }}
          >
            Activity Business Cover
          </Typography>
          <Typography
            id='modal-modal-description'
            variant='body1'
            sx={{ mt: 4 }}
          >
            Your business is outside of our online limits because of the{" "}
            <Box component='span' sx={{ fontWeight: "bold" }}>
              {refer_reason}
            </Box>
            .
          </Typography>

          <Typography
            id='modal-modal-description'
            variant='body1'
            sx={{ mt: 4 }}
          >
            Please call us on 0207 078 7540 to speak with an advisor.
          </Typography>
          <div className='center'>
            <Button variant='contained' sx={{ mt: 4 }} onClick={handleClose}>
              CONFIRM
            </Button>
          </div>
        </Box>
      </Modal>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='quote-container'>
            <div className='cost-container'>
              <Typography variant='h5' className='text'>
                Your Quote
              </Typography>
              <Typography variant='h5' className='text'>
                {handleTotal(total)}
              </Typography>
            </div>
            <Typography variant='body1' className='text tax'>
              Including Insurance Premium Tax
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className='tooltip'>
            <Typography variant='h5' className='text'>
              Choose your Cover
            </Typography>

            <Tooltip
              title={tooltipTitle()}
              placement='left-start'
              enterTouchDelay={0}
            >
              <InfoOutlinedIcon
                color='primary'
                fontSize='large'
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>

          <Typography variant='h6' className='public-liability text'>
            Employers’ Liability
          </Typography>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0" }}
          >
            Do you need Employers’ Liability Insurance?
          </Typography>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0" }}
          >
            <span className='italic-paragraf'>
              If you have any employees or volunteer staff you will require EL
              cover{" "}
            </span>
          </Typography>
          <FormControlLabel
            className=''
            control={
              <Switch
                className='switch'
                checked={!!el}
                onChange={handleEmployersLiabilityChange}
                name='liability'
              />
            }
            label='Employers’ Liability Required'
          />
          <Typography variant='body1' className='text'>
            Please enter your estimated annual wageroll
          </Typography>
          <Grid item xs={12} sm={6}>
            <NumericFormat
              customInput={Input}
              startAdornment={
                <InputAdornment position='start'>£</InputAdornment>
              }
              error={!!errors.el_wageroll}
              value={ElFEdisplay}
              onChange={handleWagerollChange}
              id='standard-basic'
              label='Wageroll'
              variant='standard'
              fullWidth
              thousandsGroupStyle='thousand'
              thousandSeparator=','
            />
            {errors.el_wageroll && (
              <FormHelperText error>{errors.el_wageroll}</FormHelperText>
            )}
          </Grid>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingBottom: "0" }}
          >
            Are some, or all of your staff volunteers?
          </Typography>
          <FormControlLabel
            control={
              <Switch
                className='switch'
                checked={!!el_volunteer_staff}
                onChange={handleVolunteerStuffChange}
                name='volunteer'
              />
            }
            label='Volunteer staff'
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='medium'
          className={classes.button}
          onClick={back}
        >
          Back
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='medium'
          className={classes.button}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
