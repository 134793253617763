import React from "react"
import { Stepper, Step, StepLabel } from "@material-ui/core"
import StepButton from "@mui/material/StepButton"
import "../../../src/App.css"
import AddressForm from "./Forms/AddressForm"
import PaymentForm from "./Forms/PaymentForm"
import CheckoutSuccess from "./CheckoutSuccess"
import HeaderComponent from "../Header/HeaderComponent"
import Activities from "./ActivityPage/Activities"
import PublicLiability from "./PublicLiability/PublicLiability"
import EmployersLiability from "./EmployersLiability/EmployersLiability"
import Equipment from "./Equipment/Equipment"
import Statements from "./Statements/Statements"
import Box from "@mui/material/Box"
import { useDevice } from "../../contexts/DeviceContext"
import { useAIForm } from "../../contexts/ActivityInsuranceFormContext"
import useStyles from "./styles"

//desktop stepper fields
const steps = [
  "Activity Selection",
  "PL Selection",
  "EL section",
  "Equipment Selection",
  "Your Details",
  "Statements",
  "Confirmation / Pay"
]

// this is the main function that renders the pages depending on the step
function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <Activities />
    case 1:
      return <PublicLiability />
    case 2:
      return <EmployersLiability />
    case 3:
      return <Equipment />
    case 4:
      return <AddressForm />
    case 5:
      return <Statements />
    case 6:
      return <PaymentForm />
    case 7:
      return <CheckoutSuccess />
    default:
      return <div>Not Found</div>
  }
}

export default function CheckoutPage() {
  const classes = useStyles()
  const { isWebApp } = useDevice()
  const { currentStepIndex, goToDesiredStep } = useAIForm()

  const setActiveStep = (step) => {
    if (step > currentStepIndex) {
      return
    }
    goToDesiredStep(step)
  }

  return (
    <>
      {/* Stepper */}
      <Box className={classes.stepper} sx={{ mb: "2.5rem" }}>
        <Stepper activeStep={currentStepIndex} className={classes.stepper}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>
                <StepButton
                  onClick={() => setActiveStep(index)}
                  color='inherit'
                >
                  {isWebApp && label}
                </StepButton>
              </StepLabel>

              {/* <StepLabel>{isWebApp && label}</StepLabel> */}
            </Step>
          ))}
        </Stepper>
      </Box>
      {_renderStepContent(currentStepIndex)}
    </>
  )
}
