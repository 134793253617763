import React from "react"
import axios from "axios"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import InstantMessageSuccess from "../Alerts/InstantMessageSuccess"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import "./customerPanel.css"
import { Grid } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"

// context
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"

const CustomerPanel = () => {
  const [isTokenValid, setIsTokenValid] = useState(false)
  const [apiResponseError, setApiResponseError] = useState("")
  const [email, setEmail] = useState("")
  const [loadingDocuments, setLoadingDocuments] = useState(false)

  const { aiFormData, updateAIFormFields } = useAIForm()

  const { customerPortalData = {} } = aiFormData // new object in context
  const { userInfo } = customerPortalData

  const { token } = useParams()

  // list of policies for customer
  const [quotes, setQuotes] = useState([])
  const [loading, setLoading] = useState(true)
  const [successDocuments, setSuccessDocuments] = useState(false)

  const changeStateOfSuccessDocuments = () => {
    setSuccessDocuments(!successDocuments)
    if (successDocuments) {
      return true
    }
  }

  const renderSuccessMessageDocuments = () => {
    if (successDocuments) {
      return (
        <InstantMessageSuccess
          severity={"success"}
          title='Success'
          message='Documents have been emailed to you'
        />
      )
    }
  }

  async function getPolicyDocumentsEmailedToYou(policyNumber) {
    try {
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.patch(
        `${API_ENDPOINT}/api/v1/portal/documents/${policyNumber}`
      )
      const data = response && response.data
      // if response is 200, set success to true, and render success message
      if (response.status === 200) {
        changeStateOfSuccessDocuments()
        setLoadingDocuments(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getQuotesFromApi(email) {
    try {
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.get(
        `${API_ENDPOINT}/api/v1/portal/quotes/${email}`
      )
      const data = response && response.data
      setQuotes(data)
    } catch (error) {
      console.log(error)
    }
  }

  const navigate = useNavigate()
  const handleLogout = () => {
    navigate(`/`)
  }

  const handlePolicyAction = (quote, action) => {
    updateAIFormFields({
      ...aiFormData,
      customerPortalData: {
        ...customerPortalData,
        userInfo: {
          ...customerPortalData.userInfo,
          policyNumber: quote.policyNumber,
          token: token
        }
      }
    })

    if (action === "update") {
      navigate(`/portal/${token}/update-policy/${quote.policyNumber}`)
    } else if (action === "renew") {
      navigate(`/portal/${token}/renew-policy/${quote.policyNumber}`)
    }
  }

  console.log("aiFormData", aiFormData)

  useEffect(() => {
    updateAIFormFields({
      aiFormData: {}
    })
  }, [])

  // render quotes data if available
  const validateQuotesData = () => {
    if (quotes.length === 0) {
      return (
        <div className='no-data'>
          <p>No quotes found</p>
        </div>
      )
    }

    console.log("quotes", quotes)

    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {changeStateOfSuccessDocuments
            ? renderSuccessMessageDocuments()
            : null}
          {/* // render success message if successDocuments is true */}
          <div
            className='title-container'
            style={{ marginTop: "1rem", marginBottom: "2rem" }}
          >
            <Typography
              variant='h5'
              className='text'
              sx={{ padding: "0", color: "#fff" }}
            >
              Customer Panel
            </Typography>
          </div>
        </Grid>

        <Typography
          variant='h5'
          color='initial'
          gutterBottom
          className='header-title'
        >
          {`Welcome  ${quotes[0].organisation.organisationFirstName} ${quotes[0].organisation.organisationLastName}`}
        </Typography>
        <Divider />
        {quotes.map((quote, index) => {
          return (
            <div key={index}>
              <Grid
                key={quote.quoteId}
                container
                /* spacing={6} */
                style={{
                  padding: "1rem",
                  marginTop: "5rem",
                  gap: "1rem",
                  boxShadow: "#41BAAE 0px 0px 3px 1px"
                }}
              >
                {/* Start of first container for input fields */}

                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant='h6' className='title'>
                      Policy Number
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant='h6'
                      className='title'
                      style={{ color: "#41BAAE" }}
                    >
                      {quote.policyNumber}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant='h6' className='title'>
                      Product
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h6' className='title'>
                      {quote.productName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant='h6' className='title'>
                      Insurer
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h6' className='title'>
                      {quote.insurerName}
                    </Typography>
                  </Grid>
                </Grid>
                {/* End of first container for input fields */}
                {/* Start of second container for input fields */}
                <Divider style={{ marginTop: "2rem" }} />
                <Grid container className='bottom-container'>
                  <Grid container className='d-flex'>
                    <Grid item xs={6}>
                      <Button
                        disabled={quote.status === "RENEWING"}
                        variant='contained'
                        color='primary'
                        onClick={() => handlePolicyAction(quote, "update")}
                      >
                        Update
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1' className='title'>
                        Update your personal details or Policy cover
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className='d-flex'>
                    <Grid item xs={6}>
                      <Button
                        disabled={
                          quote.status !== "RENEWALSENT" ||
                          quote.notRenewingReason !== null
                        }
                        variant='contained'
                        color='primary'
                        onClick={() => handlePolicyAction(quote, "renew")}
                      >
                        Renew
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1' className='title'>
                        Renew your Policy and optionally update your details and
                        cover
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container className='d-flex'>
                    <Grid item xs={6}>
                      <LoadingButton
                        loading={loadingDocuments}
                        variant='contained'
                        color='primary'
                        size='medium'
                        onClick={() => {
                          getPolicyDocumentsEmailedToYou(quote.policyNumber)
                          setLoadingDocuments(true)
                        }}
                      >
                        Documents
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1' className='title'>
                        Get your Policy documents emailed to you
                      </Typography>
                    </Grid>
                  </Grid>
                  {/*               <Grid container className='d-flex'>
                    <Grid item xs={6}>
                      <Button variant='contained' color='primary'>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1' className='title'>
                        Cancel your Policy
                      </Typography>
                    </Grid>
                  </Grid>
                      */}
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "2rem" }} />
            </div>
          )
        })}

        <div className='flex-container'>
          <Button
            variant='outlined'
            color='primary'
            style={{ marginTop: "2rem" }}
            onClick={handleLogout}
          >
            Log Out
          </Button>
        </div>
      </>
    )
  }

  // validate token from url
  async function validateToken() {
    const validToken = token // pass token from url

    const dataToSend = {
      token: validToken,
      ipAddress: ""
    }
    try {
      const API_ENDPOINT =
        process && process.env && process.env.REACT_APP_API_URL
      const response = await axios.post(
        `${API_ENDPOINT}/api/v1/policy-holder-auth`,
        dataToSend
      )
      const data = response && response.data
      const { validity, policyHolder, email, expiresOn } = data // data is an object with 4 properties
      if (response.status === 200) {
        setEmail(email)
        setIsTokenValid(validity)
        getQuotesFromApi(email)
      }
    } catch (error) {
      console.log(error)
      setApiResponseError(error)
    } finally {
      console.log("success")
    }
  }

  useEffect(() => {
    /* const token = localStorage.getItem("token") */

    validateToken()
  }, [])

  return (
    // if token is valid, show customer panel
    isTokenValid ? (
      validateQuotesData()
    ) : (
      <>
        {apiResponseError && (
          <InstantMessageSuccess
            title={"Error"}
            message={"Token invalid or expired"}
            severity={"error"}
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </Box>
      </>
    )
  )
}

export default CustomerPanel
