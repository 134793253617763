import React, { useState } from 'react';
import styled from '@emotion/styled';
import logo from '../../images/logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router';

const Header = styled.header`
	display: flex;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	padding-bottom: 2rem;
	padding-top: 1rem;
`;

const H3 = styled.h3`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	/* identical to box height */
	color: #41baae;
	padding: 0;
	margin: 0;
`;

const H6 = styled.h6`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	padding: 0;
	margin: 0;
	color: #207ea9;
`;

const LogoContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LogoImg = styled.img`
	height: 40px;
`;

function HeaderComponent() {
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<Header className="header">
				<IconButton
					sx={{ width: 0, height: 0, padding: 1, margin: 0 }}
					color="primary"
					aria-label="menu"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleMenuOpen}
				>
					<MenuIcon />
				</IconButton>
				<LogoContainer>
					<LogoImg alt="Activity insurance logo" src={logo} />
					<div className="text-container">
						<H3>Activity Business Cover</H3>
						<H6>Specialist Insurance Provider</H6>
					</div>
				</LogoContainer>
				<Box marginLeft="auto">
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
					>
						<MenuItem onClick={() => {
							navigate('/');
							handleMenuClose();
						}}>Home</MenuItem>
						<MenuItem onClick={() => {
							navigate('/customer-login');
							handleMenuClose();
						}}>Log In</MenuItem>
					</Menu>
				</Box>
			</Header>
		</Box>
	);
}

export default HeaderComponent;
