import React, { useEffect, useState } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import dayjs from "dayjs"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"

// context
import { useAIForm } from "../../../../../contexts/ActivityInsuranceFormContext"
import { useDevice } from "../../../../../contexts/DeviceContext"

// modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "10px",
  bgcolor: "background.paper",
  border: "2px solid #41BAAE",
  boxShadow: 24,
  p: 4
}

const getModalText = (policyStatus, referReason) => {
  if (policyStatus === "LAPSED") {
    return {
      title: "Activity Business Cover",
      description: (
        <>
          <div>This policy has lapsed</div>
          <br />
          <div>Please call us on 0207 078 7540 to speak with an advisor.</div>
        </>
      )
    }
  }
  return {
    title: "Activity Business Cover",
    description: (
      <>
        <div>
          Your business is outside of our on-line limits because of the{" "}
          <strong>{referReason}</strong>
        </div>
        <br />
        <div>Please call us on 0207 078 7540 to speak with an advisor.</div>
      </>
    )
  }
}

const DateOfChange = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isRenewal = location.pathname.includes("renew")
  const { policyNumberFromPortal } = useParams()
  const { isMobileApp, isWebApp } = useDevice()
  const { aiFormData, updateAIFormFields } = useAIForm()

  const [modalOpen, setModalOpen] = useState(false)

  const { customerPortalData } = aiFormData
  const {
    renewalDate,
    policyStartDate = dayjs(new Date()),
    startDate,
    refer,
    referReason,
    policyStatus
  } = customerPortalData || {}

  const [loading, setLoading] = useState(true)

  const parseDate = (dateString) => {
    return dayjs(dateString, "DD-MM-YYYY")
  }

  // selected date value from calendar
  const handleSetPolicyStartDate = (newValue) => {
    const formattedDate = newValue.format("DD-MM-YYYY")
    updateAIFormFields({
      ...aiFormData,
      customerPortalData: {
        ...customerPortalData,
        startDate: formattedDate,
        policyStartDate: formattedDate
      }
    })
  }

  // returns next 30 days for policy start date calendar picker
  function getNextMonthDate() {
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    return date
  }

  // format date for calendar picker
  useEffect(() => {
    if (startDate) {
      const formattedDate = dayjs(startDate)
      updateAIFormFields({
        ...aiFormData,
        customerPortalData: {
          ...customerPortalData,
          startDate: formattedDate
        }
      })
    }
    setLoading(false)

    // Check policy status
    if (policyStatus === "LAPSED" || refer) {
      setModalOpen(true)
    }
  }, [])

  const getDefaultDate = () => {
    return isRenewal ? parseDate(startDate) : parseDate(startDate)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    navigate("/customer-login")
  }

  // Get modal text based on policy status and refer status
  const { title, description } = getModalText(policyStatus, referReason)

  return (
    <div className=''>
      <Typography
        variant='h6'
        className='text'
        color='primary'
        style={{ paddingBottom: "1rem", paddingTop: "2rem" }}
      >
        {isRenewal ? "Renewal Date" : "Date of Change"}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {isWebApp && (
          <DesktopDatePicker
            disabled={isRenewal}
            disablePast={true}
            maxDate={getNextMonthDate()}
            label={isRenewal ? "Renewal Date" : "Date of Change"}
            inputFormat='DD-MM-YYYY'
            value={getDefaultDate()}
            onChange={handleSetPolicyStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
        )}

        {isMobileApp && (
          <MobileDatePicker
            disabled={isRenewal}
            maxDate={getNextMonthDate()}
            disablePast={true}
            label={isRenewal ? "Renewal Date" : "Date of Change"}
            inputFormat='DD-MM-YYYY'
            value={getDefaultDate()}
            onChange={handleSetPolicyStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </LocalizationProvider>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          sx={{
            ...style,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography
            id='modal-title'
            variant='h6'
            component='h2'
            textAlign='center'
            color={"#41BAAE"}
          >
            {title}
          </Typography>
          <Typography id='modal-description' sx={{ mt: 2 }}>
            {description}
          </Typography>
          <Button
            variant='contained'
            sx={{ mt: 4 }}
            onClick={handleCloseModal}
            autoFocus
          >
            CONFIRM
          </Button>
        </Box>
      </Modal>
    </div>
  )
}

export default DateOfChange
