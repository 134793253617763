import React, { useState, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"

import { createTheme, ThemeProvider } from "@mui/material/styles"
import "./paymentform.css"
import Divider from "@mui/material/Divider"
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { useDevice } from "../../../contexts/DeviceContext"
import { useAIForm } from "../../../contexts/ActivityInsuranceFormContext"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import StripePayment from "../StripePayment/StripePayment"

const theme = createTheme({
  palette: {
    primary: {
      main: "#41BAAE",
      contrastText: "#fff"
    }
  }
})

const STRIPE_CONNECTED_ACCOUNT_KEY = `${process.env.REACT_APP_STRIPE_CONNECTED_ACCOUNT_ID}`
const STRIPE_KEY = `${process.env.REACT_APP_STRIPE_KEY}`
const stripePromise = loadStripe(STRIPE_KEY, {
  stripeAccount: STRIPE_CONNECTED_ACCOUNT_KEY
})

export default function PaymentForm() {
  const { isMobileApp, isWebApp } = useDevice()
  const [showStripe, setShowStripe] = useState(false)

  const { aiFormData, activityQuoteResponse, back } = useAIForm()
  const {
    total,
    equipment_premium,
    el_premium,
    pl_premium,
    ipt_amt
  } = activityQuoteResponse[0]

  const {
    pl_cover_amt,
    pl_turnover,
    el_wageroll,
    equipment_cover_amt,
    organisation = {}
  } = aiFormData
  const {
    organisationFirstName = "",
    organisationLastName = "",
    organisationName = "",
    emailAddress = "",
    phone = "",
    address = {}
  } = organisation
  const { postcode = "", address1 = "", address4 = "" } = address

  const [policyStartDate, setPolicyStartDate] = React.useState(
    dayjs(new Date())
  )

  //selected date value from calendar
  const handleSetPolicyStartDate = (newValue) => {
    setPolicyStartDate(newValue)
  }

  // returns next 30 days for policy start date calendar picker
  function getNextMonthDate() {
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    return date
  }

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <div className='public-liabliliy-container'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='quote-container'>
            <div className='cost-container'>
              <Typography variant='h5' className='text'>
                Your Quote
              </Typography>
            </div>

            {/* TODO: replace data with props from previous pages or from API data */}
            <div className='horizontal-display'>
              <Typography variant='body2' className='text tax'>
                Public Liability Cover Level {"£" + pl_cover_amt + "m"}
              </Typography>
            </div>

            <div className='horizontal-display'>
              <Typography variant='body2' className='text tax'>
                Turnover{" "}
                {" " +
                  "£" +
                  Number(parseFloat(pl_turnover).toFixed(2)).toLocaleString(
                    "en"
                  )}
              </Typography>
            </div>

            <div className='horizontal-display'>
              <Typography variant='body2' className='text tax font-bold'>
                Public Liability Premium
              </Typography>
              <Typography variant='body2' className='text tax font-bold'>
                {"£" + Number(pl_premium).toFixed(2)}
              </Typography>
            </div>
            {el_wageroll && (
              <div className='horizontal-display'>
                <Typography variant='body2' className='text tax'>
                  Employers liability Wageroll{" "}
                  {" " +
                    "£" +
                    Number(parseFloat(el_wageroll).toFixed(2)).toLocaleString(
                      "en"
                    )}
                </Typography>
              </div>
            )}

            {el_premium !== 0 && (
              <div className=' horizontal-display'>
                <Typography variant='body2' className='text tax font-bold'>
                  Employers liability Premium
                </Typography>
                <Typography variant='body2' className='text tax font-bold'>
                  {"£" + Number(el_premium).toFixed(2)}
                </Typography>
              </div>
            )}

            {equipment_cover_amt && (
              <div className='horizontal-display'>
                <Typography variant='body2' className='text tax'>
                  Equipment cover level
                  {" " +
                    "£" +
                    Number(
                      parseFloat(equipment_cover_amt).toFixed(2)
                    ).toLocaleString("en")}
                </Typography>
              </div>
            )}
            {equipment_premium !== 0 && (
              <div className='horizontal-display'>
                <Typography variant='body2' className='text tax font-bold'>
                  Equipment cover Premium
                </Typography>
                <Typography variant='body2' className='text tax font-bold'>
                  {" £" + Number(equipment_premium).toFixed(2)}
                </Typography>
              </div>
            )}
            <div className='horizontal-display'>
              <Typography variant='body2' className='text tax font-bold'>
                Insurance Premium Tax
              </Typography>
              <Typography variant='body2' className='text tax font-bold'>
                {"£" + Number(ipt_amt).toFixed(2)}
              </Typography>
            </div>
            <div className='horizontal-display' style={{ paddingTop: "1rem" }}>
              <Typography variant='h5' className='text tax'>
                Total Premium
              </Typography>
              <Typography variant='h5' className='text tax'>
                {"£" + Number(total).toFixed(2)}
              </Typography>
            </div>
          </div>
        </Grid>
        {/* end of quote container */}

        <Grid item xs={12} sm={12}>
          <Typography
            variant='body1'
            className='text'
            style={{ paddingTop: "2rem" }}
          >
            Thank you for your quotation request.
          </Typography>
          <Typography variant='body1' className='text'>
            We have emailed the quotation documents to you which includes all of
            the terms and conditions. If you would like to proceed please choose
            a start date below and proceed to payment.
          </Typography>
          <Typography
            variant='h5'
            className='text'
            style={{ paddingBottom: "0", paddingTop: "1rem" }}
          >
            Quote Confirmation
          </Typography>

          <div className=''>
            {/* TODO: replace data with props from previous pages or from API data */}
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Policyholder</Typography>
              <Typography variant='body1'>
                {organisationFirstName + " " + organisationLastName}
              </Typography>
            </div>
            <Divider />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Business name</Typography>
              <Typography variant='body1'>{organisationName}</Typography>
            </div>
            <Divider />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Email</Typography>
              <Typography variant='body1'>{emailAddress}</Typography>
            </div>
            <Divider orientation='horizontal' />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Phone</Typography>
              <Typography variant='body1'>{phone}</Typography>
            </div>
            <Divider orientation='horizontal' />
            <div className='horizontal-display-2'>
              <Typography variant='body1'>Address</Typography>
              <Typography variant='body1'>{address1}</Typography>
            </div>

            <div className='horizontal-display'>
              <Typography variant='body1'></Typography>
              <Typography variant='body1'>{address4}</Typography>
            </div>

            <div className='horizontal-display'>
              <Typography variant='body1'></Typography>
              <Typography variant='body1'>{postcode.toUpperCase()}</Typography>
            </div>
            <Divider orientation='horizontal' />
          </div>

          <Grid item xs={12} sm={12} style={{ paddingTop: "2.5rem" }}>
            <Typography
              variant='h5'
              className='text'
              style={{ paddingBottom: "1rem" }}
            >
              Choose Your Start Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {isWebApp && (
                <DesktopDatePicker
                  disablePast={true}
                  maxDate={getNextMonthDate()}
                  label='POLICY START DATE'
                  inputFormat='DD/MM/YYYY'
                  value={policyStartDate}
                  onChange={handleSetPolicyStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}

              {isMobileApp && (
                <MobileDatePicker
                  maxDate={getNextMonthDate()}
                  disablePast={true}
                  label='POLICY START DATE'
                  inputFormat='DD/MM/YYYY'
                  value={policyStartDate}
                  onChange={handleSetPolicyStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={12} style={{ paddingTop: "2rem" }}>
            <Typography variant='h5' className='text'>
              Statement
            </Typography>
            <Typography variant='body1' className='text'>
              This sale has been made on a non-advised basis.
            </Typography>

            <Typography variant='body1' className='text'>
              The policy will meet the demands and needs of a policyholder
              wishing to insure their business against bodily injury and
              third-party property damage claims, injury/illness to employees
              and damage to their equipment caused by insured perils including
              fire, flood, storm and theft etc. assuming that all relevant
              options were selected.
            </Typography>

            <Typography variant='body1' className='text'>
              Please note the Liability Insurance (Public and Products Liability
              & Employers’ Liability) is provided on a non-refundable basis due
              to the nature of the activities being undertaken. If Equipment
              cover is selected this is refundable via pro-rata adjustment.
            </Typography>

            <Typography variant='body1' className='text'>
              Cover is subject to the policy terms, conditions and exclusions.
            </Typography>

            <Typography variant='body1' className='text'>
              You have the right to cancel this insurance policy within 14 days
              of receiving the policy documentation and receive a full refund of
              any premium paid, provided that there have been no claims either
              paid, reported or outstanding, less the administration fee (if
              applicable) to cover operational costs. Following this 14 day
              period the policy premium becomes due and You may not be entitled
              to a refund of premium and the policy may run for its full term.
              Your Insurers may cancel this policy with 14 days’ written notice,
              and you will be entitled to a pro-rata return of premium, provided
              no claims have been made under the policy.
            </Typography>

            <Typography variant='body1' className='text'>
              If you require advice or would like to request additional
              insurance cover/products for your business please contact us on
              0207 078 7540.
            </Typography>

            {equipment_cover_amt !== "" && (
              <Typography variant='body1' className='text'>
                Click{" "}
                <span>
                  <a
                    href='https://quote.activitybusinesscover.co.uk/static/docs/Activity%20Business%20Cover%20Property%20Wording%2012.04.2022.pdf'
                    style={{
                      color: "#41BAAE",
                      fontWeight: "600",
                      textDecoration: "none"
                    }}
                    target='blank'
                  >
                    here
                  </a>{" "}
                </span>{" "}
                for the Property Insurance Policy wording
              </Typography>
            )}

            <Typography variant='body1' className='text'>
              Click{" "}
              <span>
                <a
                  href='https://quote.activitybusinesscover.co.uk/static/docs/Activity%20Business%20Cover%20Liability%20Wording%2012.04.2022.pdf'
                  style={{
                    color: "#41BAAE",
                    fontWeight: "600",
                    textDecoration: "none"
                  }}
                  target='blank'
                >
                  here
                </a>{" "}
              </span>{" "}
              for the Liability Insurance Policy Wording
            </Typography>

            <Divider style={{ background: "#41BAAE" }} />
          </Grid>

          <div className={!showStripe ? "button-container" : "button-hide"}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              size='medium'
              onClick={back}
            >
              Back
            </Button>
            <Button
              className='proceed-button'
              sx={{ color: "#fff", margin: "auto" }}
              variant='contained'
              onClick={setShowStripe}
            >
              Proceed with Purchase / Confirm Payment
            </Button>
          </div>
          {showStripe && (
            <div className='stripe-container'>
              <Elements stripe={stripePromise}>
                <StripePayment policyStartDate={policyStartDate} />
              </Elements>
            </div>
          )}
        </Grid>
      </div>
    </ThemeProvider>
  )
}
